import UpdateSchema from '@assets/schema/user/self-update.json';
import { Button } from '@common/Button/Button';
import { UncontrolledDrawer } from '@common/Drawer/UncontrolledDrawer';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { ReadonlyFormControl } from '@common/Form/ReadonlyFormControl';
import { NavItemClickable } from '@common/Nav/NavItem';
import { Paragraph } from '@common/Typography/Paragraph';
import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { updateUser } from '@services/api/users';
import { UserType } from '@services/model/user';
import { selectUser } from '@store/user/selectors';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type ChangePasswordFormProps = {
  onSuccess: () => void;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onSuccess }) => {
  const current = useSelector(selectUser);
  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: UpdateSchema as SchemaObject,
    defaultValues: current,
  });

  const { t } = useTranslation();

  const { call: callOnUpdate, isLoading } = useApi({
    method: updateUser,
    initialLoading: false,
    onSuccess: useCallback<UseApiSuccessEvent<UserType>>(
      ({ onSuccessNotification }) => {
        onSuccessNotification(t('Your profile has been updated'));

        onSuccess();
      },
      [t, onSuccess],
    ),
    onError: onErrorEvent,
  });

  const onSubmit = (data: UserType) => {
    callOnUpdate(current.id, data);
  };

  return (
    <FormProvider {...controls}>
      <Form schema={schema}>
        <form
          noValidate
          onSubmit={controls.handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              md={6}>
              <FormControl path="firstname" />
            </Grid>
            <Grid
              item
              md={6}>
              <FormControl path="lastname" />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="email"
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <ReadonlyFormControl
                path="password"
                toggle={({ onToggle }) => (
                  <Paragraph onClick={onToggle}>{t('Click here to change password')}</Paragraph>
                )}
              />
            </Grid>
          </Grid>
          <Box mt={5}>
            <Button
              fluent
              loading={isLoading}
              type="submit"
              disabled={!controls.formState.isValid}
              color="primary">
              {t('Save')}
            </Button>
          </Box>
        </form>
      </Form>
    </FormProvider>
  );
};

export const ChangePassword = () => {
  const { t } = useTranslation();

  return (
    <UncontrolledDrawer
      title={t('Settings')}
      cancelable
      handler={({ onOpen }) => (
        <NavItemClickable
          title={t('Settings')}
          onClick={onOpen}
        />
      )}>
      {({ onClose }) => (
        <>
          <Paragraph space>{t('Account settings')}</Paragraph>
          <ChangePasswordForm onSuccess={onClose} />
        </>
      )}
    </UncontrolledDrawer>
  );
};
