import { useEffect, useRef, useState } from 'react';

export type UseChunkedListProps<T> = {
  data: T[];
  visible?: number | false;
};

export const useChunkedList = <E extends HTMLElement, T extends object>({
  data,
  visible = 10,
}: UseChunkedListProps<T>) => {
  const perPage = visible || 10;
  const total = data.length;
  const [end, setEnd] = useState(perPage);

  const rootRef = useRef<E>(null);

  useEffect(() => {
    const root = rootRef.current;

    if (!root?.parentElement) {
      return;
    }

    const after = document.createElement('div');
    after.style.height = '1px';
    after.style.width = '100%';

    root.parentElement?.append(after);

    const observer = new IntersectionObserver((intersections) => {
      const intersection = intersections.shift();

      if (intersection?.isIntersecting) {
        setEnd((end) => Math.min(end + perPage, total));
      }
    });

    observer.observe(after);

    return () => {
      observer.disconnect();
      after.remove();
    };
  }, [perPage, total]);

  return {
    data: data.slice(0, end),
    visible: Math.min(end, data.length),
    rootRef,
  };
};
