import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon, IconTypes } from './Icon';

type LogoLinkProps = {
  href: string;
  title: string;
  icon: IconTypes;
};

export const IconLink: React.FC<LogoLinkProps> = ({ title, href, icon }) => {
  const { t } = useTranslation();

  return (
    <StyledLink
      href={href}
      title={t(title)}>
      <Icon
        name={icon}
        width="1.15em"
      />
      <span>{t(title)}</span>
    </StyledLink>
  );
};

const StyledLink = styled('a')`
  text-decoration: none;
  font-weight: bold;
  color: ${(props) => props.theme.palette.common.black};

  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5em;
  align-items: center;
  white-space: nowrap;

  font-size: 1.15em;
`;
