import { NotificationType } from '@services/model/notify';
import { selectNotifications } from '@store/notify/selectors';
import { first } from '@utils/fp';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Snackbar } from './Snackbar';

export const SnackbarContainer = () => {
  const notifications = useSelector(selectNotifications);

  const [notification, setNotification] = useState<NotificationType | null>(null);

  useEffect(() => {
    const _notifications = notifications;
    const timeout = setTimeout(() => {
      setNotification(first(_notifications));
    }, 500);

    return () => clearTimeout(timeout);
  }, [notifications]);

  if (!notification) {
    return null;
  }

  return (
    <Snackbar
      {...notification}
      onClose={() => setNotification(null)}
    />
  );
};
