import { Spinner } from '@common/Spinner/Spinner';
import Box from '@mui/material/Box';
import { AppDispatch } from '@store/types';
import { signOutUser } from '@store/user';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const SignOutPage = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(signOutUser());
  }, [dispatch]);

  return (
    <Box display="flex">
      <Spinner centered />
    </Box>
  );
};
