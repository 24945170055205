import { IconButton } from '@common/Button/IconButton';
import { media } from '@common/styles/utils';
import { Heading } from '@common/Typography/Heading';
import GenericDrawer from '@mui/material/Drawer';
import { css, styled } from '@mui/material/styles';
import { ScrollbarsCss } from '@utils/scrollbars';
import { FCC, WithStyle } from '@utils/types';
import { FiX } from 'react-icons/fi';

import { DrawerContent } from './DrawerContent';
import { DrawerHeader } from './DrawerHeader';

export type DrawerHandlerProps = {
  onOpen: () => void;
};

export type DrawerChildProps = {
  onClose: () => void;
};

export type DrawerProps = {
  title: string;
  cancelable?: boolean;
  actions?: React.ReactElement;
  fixed?: boolean;
  isOpen: boolean;
  paddedContent?: boolean;
  variant?: 'persistent' | 'temporary';
} & Partial<DrawerChildProps>;

export const Drawer: FCC<WithStyle<DrawerProps>> = ({
  title,
  children,
  cancelable,
  actions,
  isOpen,
  onClose,
  fixed = true,
  paddedContent = true,
  className,
  variant = 'temporary',
}) => (
  <StyledDrawer
    hideBackdrop
    fixed={fixed}
    className={className}
    open={isOpen}
    variant={variant}
    anchor="right">
    <StyledDrawerHeader>
      <Heading
        size={5}
        gutterBottom={false}>
        {title}
      </Heading>
      {actions}
      {cancelable && (
        <StyledCloseButton
          color="contained"
          onClick={onClose}
          icon={<FiX />}
        />
      )}
    </StyledDrawerHeader>
    <DrawerContent padding={paddedContent}>{children}</DrawerContent>
  </StyledDrawer>
);

const StyledCloseButton = styled(IconButton)`
  margin-right: -1rem;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledDrawer = styled(GenericDrawer)<{ fixed: boolean }>`
  .MuiPaper-root {
    overflow-y: auto;
    min-width: 100%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    z-index: 1200;

    ${ScrollbarsCss}
  }

  padding: 0 !important;

  ${(props) => !props.fixed && `left: calc(100% - 400px);`}

  ${DrawerContent}:not(:last-child) {
    padding-bottom: 0;
  }

  ${media(
    'md',
    () => css`
      padding: 1rem;

      .MuiPaper-root {
        min-width: 400px;
        max-width: 400px;
        margin: 1rem;
        max-height: calc(100% - 2rem);

        @media (min-width: 1200px) {
          max-width: 35rem;
        }
      }
    `,
  )}
`;
