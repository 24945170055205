import { Modal } from '@common/Modal/Modal';
import { replace } from '@lagunovsky/redux-react-router';
import { dirname } from '@utils/fp';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { ToDoListCreateModalBody } from './ToDoListCreateModalBody';

type ToDoListCreateModalProps = {
  backTo: string;
};

export const ToDoListCreateModal: React.FC<ToDoListCreateModalProps> = ({ backTo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { type } = useParams();

  const onClose = () => {
    dispatch(replace(dirname(pathname)));
  };

  return (
    <Modal
      cancelable
      open
      title={t('Add recommendation')}
      onClose={onClose}>
      <ToDoListCreateModalBody
        onClose={onClose}
        type={type}
      />
    </Modal>
  );
};
