import { styled } from '@mui/system';

type ContentNavbarProps = {
  children: React.ReactNode;
  tabs?: boolean;
};

export const ContentNavbar: React.FC<ContentNavbarProps> = ({ children, tabs = false }) => (
  <ContentNavbarStyled tabs={tabs}>{children}</ContentNavbarStyled>
);

const ContentNavbarStyled = styled('div')<{ tabs: boolean }>`
  padding: ${(props) => (props.tabs ? '0rem' : '1rem')};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  grid-area: navbar;
`;
