import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ElementType, ReactNode } from 'react';

type LabelProps = {
  id: string;
  hasError?: boolean;
  required?: boolean;
  children: ReactNode;
};

const LabelStyled = styled(Typography, {
  shouldForwardProp: (prop) => !['hasError', 'component'].includes(prop as string),
})<{ hasError?: boolean; component: ElementType; htmlFor: string }>`
  line-height: 1.4;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;

  ${({ theme, hasError }) =>
    hasError &&
    `color: ${theme.palette.error.main};
  `}
`;

export const Label = ({ id, children, required, hasError }: LabelProps) => (
  <LabelStyled
    component="label"
    htmlFor={id}
    hasError={hasError}>
    {children}
    {required && <Typography>*</Typography>}
  </LabelStyled>
);
