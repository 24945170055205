import { Box, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { WithStyle } from '@utils/types';

type SpinnerStyledProps = {
  size?: string | number;
  centered?: boolean; // designed to handle page loads
};

const SpinnerStyled = styled(Box)<SpinnerStyledProps>`
  display: flex;
  flex-direction: column;

  ${({ centered }) =>
    centered &&
    `
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;
  `}
`;

export const Spinner = ({ size, ...others }: WithStyle<SpinnerStyledProps>) => (
  <SpinnerStyled {...others}>
    <CircularProgress
      size={size}
      color="inherit"
    />
  </SpinnerStyled>
);
