import { error, success } from '@store/notify';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export type NotificationHandler = (message: string) => void;

export const useNotifications = () => {
  const dispatch = useDispatch();

  return {
    onSuccessNotification: useCallback<NotificationHandler>(
      (msg) => {
        dispatch(success(msg));
      },
      [dispatch],
    ),
    onErrorNotification: useCallback<NotificationHandler>(
      (msg) => {
        dispatch(error(msg));
      },
      [dispatch],
    ),
  };
};
