import { IconLink } from '@common/Icon/IconLink';
import { useMediaQuery } from '@mui/material';
import { Box, styled, useTheme } from '@mui/material';
import { ReactComponent as FFWAsset } from '@planergia/assets/images/inverse/logo1.svg';
import { ReactComponent as SPIAsset } from '@planergia/assets/images/inverse/logo2.svg';
import { ReactComponent as CRISAsset } from '@planergia/assets/images/inverse/logo3.svg';
import { ReactComponent as GdowAsset } from '@planergia/assets/images/inverse/logo4.svg';
import { ReactComponent as GZMAsset } from '@planergia/assets/images/inverse/logo5.svg';
import { ReactComponent as PlanergiaAsset } from '@planergia/assets/images/inverse/logo6.svg';

export const CompaniesFooter: React.FC<{ className?: string }> = ({ className }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <CompaniesFooterContainer className={className}>
      <PlanergiaAsset
        style={{
          margin: 'auto',
          width: '10em',
        }}
      />
      <CompaniesPlanergiaInfo>
        <IconLink
          icon="desktop"
          title="ogrzej.planergia.pl"
          href="https://ogrzej.planergia.pl"
        />
        <IconLink
          icon="desktop"
          title="dotacje.planergia.pl"
          href="https://dotacje.planergia.pl"
        />
        <IconLink
          icon="mail"
          title="biuro@planergia.pl"
          href="mailto:buiro@planergia.pl"
        />
        <IconLink
          icon="phone"
          title="732 882 349"
          href="tel:732 882 349"
        />
      </CompaniesPlanergiaInfo>
      <CompaniesLogosLine>
        <GdowAsset />
        <GZMAsset />
        <FFWAsset />
        <SPIAsset />
        <CRISAsset />
      </CompaniesLogosLine>
    </CompaniesFooterContainer>
  );
};

const CompaniesFooterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 2em 0;

  margin-left: auto;
  margin-right: auto;
`;

const CompaniesPlanergiaInfo = styled(Box)`
  gap: 1em;

  padding: 2em 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.common.black};

  > svg:first-of-type {
    flex: 1;
  }

  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;

  ${(props) => props.theme.breakpoints.up('md')} {
    grid-template-columns: repeat(4, calc(100% / 4));
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CompaniesLogosLine = styled(Box)`
  gap: 2em;
  padding: 1em 0;

  display: grid;
  grid-template-columns: repeat(3, 33%);
  justify-items: center;

  > svg {
    max-height: 40px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > svg {
      max-height: 80px;
    }
  }
`;
