import { useCallback, useState } from 'react';

type UseToggle = {
  initialState?: boolean;
};

export const useToggle = ({ initialState = false }: UseToggle) => {
  const [state, setState] = useState<boolean>(initialState);

  return {
    state,
    off: useCallback(() => setState(false), []),
    on: useCallback(() => setState(true), []),
    toggle: useCallback(() => setState((prev) => !prev), []),
  };
};
