import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

type CheckboxInputProps = {
  label: string;
  [key: string]: any;
};

export const CheckboxInput: React.FC<CheckboxInputProps> = ({ label, ...props }) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      label={t(label)}
      labelPlacement="end"
      control={
        <Checkbox
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (props.onChange) {
              props.onChange(e);
            }

            if (props.onBlur) {
              props.onBlur();
            }
          }}
          color="primary"
        />
      }
    />
  );
};
