import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, ApiCaller } from '@services/api/utils';

const defaultClient = async () => api('');

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    client: defaultClient,
    token: '',
  },
  reducers: {
    setClient(state, action: PayloadAction<ApiCaller | undefined>) {
      state.token = '';
      state.client = action.payload || defaultClient;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const { setClient, setToken } = clientSlice.actions;
export const { reducer } = clientSlice;
