import CreateTodoItemSchema from '@assets/schema/client/todo.json';
import { Button } from '@common/Button/Button';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { TextArea } from '@common/Form/TextArea';
import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import { usePermission } from '@hooks/usePermission';
import { Box, Grid, styled } from '@mui/material';
import { updateClientToDo } from '@services/api/client';
import { format } from 'date-fns';
import { get, merge } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useClientContext } from '../../Tabs/useClientTabsContext';
import { TitleField } from '../CreateModal/TitleField';
import { ToDoListItemRemoveBtn } from '../ToDoListItemRemoveBtn';
import { ToDoItem } from '../types';
import { Params } from './types';

type ModalBodyProps = {
  item: ToDoItem;
  onUpdated: () => void;
  onRemoved: () => void;
};

export const ModalBody: React.FC<ModalBodyProps> = ({ item, onRemoved, onUpdated }) => {
  const { t } = useTranslation();
  const { id, type } = useParams<Params>();
  const { canWrite } = usePermission();
  const { client, patch } = useClientContext();

  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: CreateTodoItemSchema as unknown as SchemaObject,
    defaultValues: {
      ...item,
      group: type,
    },
  });

  const { watch, setValue } = controls;

  const isCompleted = watch('completed');
  const previousState = useRef(isCompleted);

  useEffect(() => {
    if (previousState.current === isCompleted) {
      return;
    }

    previousState.current = isCompleted;

    if (!isCompleted) {
      setValue('completionTime', '', {
        shouldValidate: true,
      });
    } else {
      const today = format(new Date(), 'yyyy-MM-dd');

      setValue('completionTime', today, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [isCompleted, setValue]);

  const { call, isLoading } = useApi({
    method: updateClientToDo,
    onError: onErrorEvent,
    onSuccess: useCallback<UseApiSuccessEvent<ToDoItem>>(
      ({ data, onSuccessNotification }) => {
        onSuccessNotification(t('Recommendation updated'));

        patch((client) =>
          merge({}, client, {
            [type!]: get(client, type!, []).map((item: ToDoItem) => (item.id === data.id ? data : item)),
          }),
        );

        onUpdated();
      },
      [t, type, patch, onUpdated],
    ),
  });

  const onSubmit = (values: ToDoItem) => {
    call(type!, client.id, values);
  };

  return (
    <FormProvider {...controls}>
      <form onSubmit={controls.handleSubmit(onSubmit)}>
        <Form schema={schema}>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}>
              <FormControl
                path="group"
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="type"
                control={TitleField}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="owner"
                disabled={!canWrite()}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <FormControl
                path="completed"
                disabled={!canWrite()}
              />
            </Grid>
            <Grid
              item
              xs={6}>
              <FormControl
                path="completionTime"
                lang="pl-PL"
                disabled={!isCompleted || !canWrite()}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="description"
                disabled={!canWrite()}
                control={TextArea}
              />
            </Grid>
          </Grid>
          {canWrite() && (
            <StyledButtonWrapper mt={5}>
              <ToDoListItemRemoveBtn
                {...{ id, type }}
                title={t('Remove')}
                onSuccess={onRemoved}
              />
              <Button
                type="submit"
                loading={isLoading}
                color="primary">
                {t('Save')}
              </Button>
            </StyledButtonWrapper>
          )}
        </Form>
      </form>
    </FormProvider>
  );
};

const StyledButtonWrapper = styled(Box)`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 0 1.5rem;
`;
