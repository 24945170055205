import { Drawer } from '@common/Drawer/Drawer';
import { ContentBox } from '@common/Layout/Content/ContentBox';
import { ContentHeader } from '@common/Layout/Content/ContentHeader';
import { ContentNavbar } from '@common/Layout/Content/ContentNavbar';
import { TabLink } from '@common/TabLink/TabLink';
import { TabLinkGroup } from '@common/TabLink/TabLinkGroup';
import { useApi } from '@hooks/useApi';
import { styled } from '@mui/material/styles';
import { getClient } from '@services/api/client';
import { Client } from '@services/model/client/Client';
import { join } from '@utils/fp';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router';

import { ClientContext } from './Tabs/useClientTabsContext';
import { ToDoList } from './ToDoList/ToDoList';

export const ClientEditPage = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const {
    data = {} as Client,
    call,
    isLoading: callingOnFetch,
    patch,
  } = useApi({
    method: getClient,
    initialLoading: true,
  });

  useEffect(() => {
    if (clientId) {
      call(clientId);
    }
  }, [clientId, call]);

  return (
    <>
      <ContentHeader
        title={t('Client {{name}}', {
          name: join(data?.firstName, data?.lastName),
        })}
        subtitle={t('Client management and status view')}
      />
      <ContentNavbar tabs>
        <TabLinkGroup>
          <TabLink to="overview">{t('Overview')}</TabLink>
          <TabLink to="identity">{t('Identity')}</TabLink>
          <TabLink to="building">{t('Building')}</TabLink>
          <TabLink to="interview">{t('Interview')}</TabLink>
        </TabLinkGroup>
      </ContentNavbar>
      <ClientContext.Provider value={{ client: data, patch }}>
        <ContentBoxStyled loading={callingOnFetch}>
          <Outlet />
        </ContentBoxStyled>
        <DrawerStyled
          variant="persistent"
          fixed={false}
          paddedContent={false}
          cancelable={false}
          isOpen={true}
          title={t('Actions list')}>
          <ToDoList
            property="meetings"
            title={t('Client related actions')}
          />
          <ToDoList
            property="buildingActions"
            title={t('Building related actions')}
          />
          <ToDoList
            property="actions"
            title={t('Actions taken')}
          />
        </DrawerStyled>
      </ClientContext.Provider>
    </>
  );
};

const ContentBoxStyled = styled(ContentBox)`
  width: calc(100% - 500px);
`;

const DrawerStyled = styled(Drawer)`
  .MuiPaper-root {
    min-height: 100%;
    margin: 0;
    max-width: 500px;
    width: 100%;
  }
`;
