import { Avatar as MuiAvatar, styled } from '@mui/material';

import { textToHexColor, uf } from './utils';

type AvatarProps = {
  user: {
    firstname: string;
    lastname: string;
  };
};

const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== 'hash',
})<{ hash: string }>`
  background-color: ${({ hash }) => textToHexColor(hash)};
  width: 3rem;
  height: 3rem;
  font-size: 0.9rem;
`;

export const Avatar = ({ user }: AvatarProps) => {
  const id = user.firstname + user.lastname;

  return <StyledAvatar hash={id}>{`${uf(user.firstname)}${uf(user.lastname)}`}</StyledAvatar>;
};
