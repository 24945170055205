import { ContentBox } from '@common/Layout/Content/ContentBox';
import { ContentHeader } from '@common/Layout/Content/ContentHeader';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';

export const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentHeader
        title={t('Dashboard')}
        subtitle={t('System overview')}
      />
      <ContentBox>
        <Box height={1200} />
        <Navigate
          to="/clients/manage"
          replace
        />
      </ContentBox>
    </>
  );
};
