import { FormField } from '@common/Form/FormField';
import { StringInput } from '@common/Form/StringInput';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type UserPasswordInputProps = {
  disabled?: boolean;
};

export const UserPasswordInput = ({ disabled }: UserPasswordInputProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="password"
      render={({ field, fieldState }) => (
        <FormField
          {...field}
          isDirty={fieldState.isDirty}
          isTouched={fieldState.isTouched}
          error={fieldState.error}
          placeholder={t('Please enter your password')}
          disabled={disabled}
          control={StringInput}
          label={t('Password')}
          type="password"
        />
      )}
    />
  );
};
