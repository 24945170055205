import { Button } from '@common/Button/Button';
import { Icon } from '@common/Icon/Icon';
import { useTranslation } from 'react-i18next';

import { useRemoveUser } from './useRemoveUser';

type RemoveUserButtonProps = {
  icon?: boolean;
  id?: string;
  onSuccess: () => void;
};

export const RemoveUserButton: React.FC<RemoveUserButtonProps> = ({ icon, id, onSuccess }) => {
  const { t } = useTranslation();
  const { onRemove, isLoading } = useRemoveUser({
    id,
    onSuccess,
  });

  return (
    <Button
      onClick={onRemove}
      loading={isLoading}
      color="error"
      disabled={!id}
      title={t('Remove user')}>
      {icon && <Icon name="remove" />}
      {!icon && t('Remove')}
    </Button>
  );
};
