import { id } from '@services/id';
import { NotificationSeverity, NotificationType } from '@services/model/notify';

type UnknownNotification = Omit<NotificationType, 'id'>;

const factory =
  (severity: NotificationSeverity) =>
  (message: string): UnknownNotification => ({
    message,
    severity,
  });

export const requestId = (n: UnknownNotification): NotificationType => ({
  ...n,
  id: id('notification'),
});

export const notice = factory(NotificationSeverity.NOTICE);
export const warning = factory(NotificationSeverity.WARNING);
export const error = factory(NotificationSeverity.ERROR);
export const success = factory(NotificationSeverity.SUCCESS);
