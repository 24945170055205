import { useToggle } from '@hooks/useToggle';

import { FormControl } from './FormControl';

type ReadonlyFormControlProps = {
  path: string;
  toggle: (props: { state: boolean; onToggle: () => void }) => React.ReactNode;
};

export const ReadonlyFormControl: React.FC<ReadonlyFormControlProps> = ({ path, toggle }) => {
  const { state, toggle: _toggle } = useToggle({ initialState: true });

  return (
    <FormControl
      path={path}
      disabled={state}>
      {toggle({ state, onToggle: _toggle })}
    </FormControl>
  );
};
