import { Spinner } from '@common/Spinner/Spinner';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ScrollbarsCss } from '@utils/scrollbars';
import { WithStyle } from '@utils/types';

type ContentBoxProps = {
  list?: boolean;
  table?: boolean;
  loading?: boolean;
  children: React.ReactNode;
};

export const ContentBox: React.FC<WithStyle<ContentBoxProps>> = ({
  list,
  children,
  loading,
  className,
  table = false,
}) => (
  <ContentBoxStyled
    list={list}
    className={className}
    table={table}>
    {children}
    {loading && (
      <ContentBoxLoading>
        <Spinner />
      </ContentBoxLoading>
    )}
  </ContentBoxStyled>
);

const ContentBoxLoading = styled('div')`
  position: absolute;
  top: 0;
  bottom 0;
  left: 0;
  right: 0;

  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.palette.grey[100]};
  filter: opacity(0.5);
`;

const ContentBoxStyled = styled(Box)<{ list?: boolean; table: boolean }>`
  ${ScrollbarsCss};
  padding: ${(props) => (!props.table ? `1.5rem 1rem` : `0`)};
  position: relative;

  background: ${(props) => props.theme.palette.grey[100]};

  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  grid-area: content;

  box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.05);
`;
