import { ApiHandler, StructuredErrorResponse, withApi } from '@services/api/utils';
import { selectClient } from '@store/client/selectors';
import { AppDispatch } from '@store/types';
import { signOutUser } from '@store/user';
import { UnwrapPromiseFn } from '@utils/types';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationHandler, useNotifications } from './useNotifications';

type State<S> = {
  error: boolean;
  loading: boolean;
  data: S | undefined;
};

const product =
  <T, S = State<T>>(state: Partial<S>) =>
  (prevState: S) => ({
    error: false,
    loading: false,
    data: undefined,
    ...prevState,
    ...state,
  });

export type PatchFn<R> = (cb: (prevData: State<R>['data']) => State<R>['data']) => void;

export interface UseApiSuccessEvent<R = any> {
  (props: { data: R; onSuccessNotification: NotificationHandler }): void;
}

export interface UseApiErrorEvent {
  (props: { error: StructuredErrorResponse | undefined; onErrorNotification: NotificationHandler }): void;
}

type UseApi<F> = {
  method: ApiHandler<F>;
  initialLoading?: boolean;
  onSuccess?: UseApiSuccessEvent<UnwrapPromiseFn<F>>;
  onError?: UseApiErrorEvent;
};

const isAxiosError = (error: AxiosError | any): boolean => error instanceof AxiosError && error?.isAxiosError;

const unwrap = (error: AxiosError) => error.response?.data as StructuredErrorResponse;

export const useApi = <F extends (...args: any) => any, R = UnwrapPromiseFn<F>>({
  method,
  initialLoading = false,
  onSuccess,
  onError,
}: UseApi<F>) => {
  const dispatch: AppDispatch = useDispatch();
  const { onErrorNotification, onSuccessNotification } = useNotifications();
  const client = useSelector(selectClient);
  const [state, setState] = useState<State<R>>({
    error: false,
    loading: initialLoading,
    data: undefined,
  });

  const call = useCallback(
    async (...args: Parameters<F>): Promise<R | undefined> => {
      try {
        setState(product<R>({ loading: true }));

        const invoke = await withApi(client, method);
        const data = await invoke(...args);

        setState(product<R>({ loading: false, data }));

        if (onSuccess) {
          onSuccess({
            data,
            onSuccessNotification,
          });
        }

        return data;
      } catch (error) {
        if (!isAxiosError(error)) {
          throw error;
        }

        if ((error as AxiosError).response?.status === 401) {
          dispatch(signOutUser());
        }

        setState(product<R>({ loading: false, error: true }));

        if (onError) {
          onError({
            error: unwrap(error as AxiosError),
            onErrorNotification,
          });
        } else {
          onErrorNotification('Error occurred during request processing');
        }

        return undefined;
      }
    },
    [client, method, onSuccess, onSuccessNotification, onError, onErrorNotification, dispatch],
  );

  const patch = useCallback<PatchFn<R>>((cb) => {
    setState((prevState) => ({
      ...prevState,
      data: cb(prevState.data),
    }));
  }, []);

  return {
    call,
    data: state.data,
    isLoading: state.loading,
    isError: state.error,
    patch: patch,
    clear: () =>
      setState({
        error: false,
        loading: false,
        data: undefined,
      }),
    mock: (data: R) =>
      setState({
        error: false,
        loading: false,
        data,
      }),
  };
};
