import { Spinner } from '@common/Spinner/Spinner';
import { useApi } from '@hooks/useApi';
import { ApiHandler } from '@services/api/utils';
import { WithoutForwardRef } from '@utils/types';
import { forwardRef, useEffect } from 'react';

import { SelectInput, SelectInputProps } from './SelectInput';

type SelectInputRemoteOptions = Array<{
  value: string;
  label: string;
}>;

export type SelectInputRemoteProps = SelectInputProps & {
  call: ApiHandler<() => Promise<SelectInputRemoteOptions>>;
};

const SelectInputRemoteComponent: React.FC<SelectInputRemoteProps> = ({
  call,
  forwardRef,
  disabled = false,
  ...props
}) => {
  const {
    data = [],
    isLoading,
    call: callOnMount,
  } = useApi({
    method: call,
    initialLoading: !disabled,
  });

  useEffect(() => {
    callOnMount();
  }, [disabled, callOnMount]);

  return (
    <SelectInput
      {...props}
      startAdornment={isLoading && <Spinner size={18} />}
      disabled={disabled || isLoading}
      options={data}
      ref={forwardRef}
    />
  );
};

export const SelectInputRemote = forwardRef<HTMLInputElement, WithoutForwardRef<SelectInputRemoteProps>>(
  (props, ref) => (
    <SelectInputRemoteComponent
      {...props}
      forwardRef={ref}
    />
  ),
);
