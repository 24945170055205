import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

type TableActionLinkProps = {
  icon: React.ReactElement;
  text: string;
  to?: string;
  external?: boolean;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
};

export const TableActionLink: React.FC<TableActionLinkProps> = ({ icon, text, to = '', onClick }) => (
  <StyledLink
    to={to}
    onClick={onClick}>
    {icon}
    <span>{text}</span>
  </StyledLink>
);

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0 0.75rem;
  color: ${(props) => props.theme.palette.common.black};
  text-decoration: none;

  &:hover,
  &:focus,
  &:visited {
    color: ${(props) => props.theme.palette.common.black};
  }
`;
