import { Alert, Snackbar as SnackbarMaterial } from '@mui/material';
import { NotificationType } from '@services/model/notify';
import { pop } from '@store/notify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

type SnackbarProps = NotificationType & {
  onClose: () => void;
};

export const Snackbar = ({ id, message, severity, onClose }: SnackbarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(pop(id));
    onClose();
  };

  return (
    <SnackbarMaterial
      open
      onClose={handleClose}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      autoHideDuration={3000}>
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%', fontSize: '1.2em' }}>
        {t(message)}
      </Alert>
    </SnackbarMaterial>
  );
};
