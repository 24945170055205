import { Avatar } from '@common/Avatar';
import { Paragraph } from '@common/Typography/Paragraph';
import { Box, ClickAwayListener, Collapse, styled } from '@mui/material';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { getPermissionName, UserType } from '@services/model/user';
import { capitalize } from '@utils/fp';
import { ReactNode } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

type UserInfoPanelProps = {
  user?: UserType;
  children: ReactNode;
};

const UserInfoPanelStyled = styled(Box)`
  padding: 1.5rem 1.5rem 2rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const UserInfoDetailsStyled = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const UserInfoPanel = ({ user, children }: UserInfoPanelProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const rippleRef = useRef<TouchRippleActions>();

  const onMouseDown = (e: any) => rippleRef?.current?.start(e);
  const onMouseUp = (e: any) => rippleRef?.current?.stop(e);

  if (!user?.id) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box role="presentation">
        <UserInfoPanelStyled>
          <Box
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onClick={() => setOpen((prev) => !prev)}
            display="flex"
            flexDirection="row"
            gap="0 1rem"
            alignItems="center">
            <Avatar user={user} />
            <Box>
              <Paragraph
                space={0}
                size={2}>{`${user.firstname} ${user.lastname}`}</Paragraph>
              <Paragraph
                sx={{
                  maxWidth: '11rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                space={0}
                variant="secondary">
                {user.email}
              </Paragraph>
            </Box>
            <Box ml="auto">{open ? <FiChevronDown /> : <FiChevronUp />}</Box>
            <TouchRipple
              ref={rippleRef}
              center={false}
            />
          </Box>
        </UserInfoPanelStyled>
        <Collapse
          orientation="vertical"
          in={open}>
          <UserInfoDetailsStyled>
            {children}
            <Divider />
            <Permission>
              <Paragraph>
                <>{t('System Permission')}</>
              </Paragraph>
              <Paragraph>
                <>{t(getPermissionName(user.permission))}</>
              </Paragraph>
            </Permission>
            <Permission>
              <Paragraph>
                <>{t('County')}</>
              </Paragraph>
              <Paragraph>
                <>{t(capitalize(user.county))}</>
              </Paragraph>
            </Permission>
            <Permission>
              <Paragraph>
                <>{t('Commune')}</>
              </Paragraph>
              <Paragraph>
                <>{t(capitalize(user.commune))}</>
              </Paragraph>
            </Permission>
          </UserInfoDetailsStyled>
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
};

const Permission = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;

  p:first-child {
    font-weight: 500;
  }

  &:last-of-type {
    padding-bottom: 1rem;
  }
`;

const Divider = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;
