import { useTranslation } from 'react-i18next';

import { FormControl } from './FormControl';
import { SchemaObject } from './hooks/useJsonSchema';
import { Label } from './Label';

type ObjectFieldProps = {
  schema: SchemaObject;
  prefix: string;
};

export const ObjectField: React.FC<ObjectFieldProps> = ({ schema, prefix }) => {
  const { t } = useTranslation();

  return (
    <>
      {schema.title && <Label id={prefix}>{t(schema.title)}</Label>}
      {Object.keys(schema.properties).map((name) => (
        <FormControl path={`${prefix}.${name}`} />
      ))}
    </>
  );
};
