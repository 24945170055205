import { Button } from '@common/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { push } from '@lagunovsky/redux-react-router';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { AuthorizationSchema, AuthorizationSchemaType } from '@services/schemas/auth';
import { AppDispatch } from '@store/types';
import { signInUser } from '@store/user';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { UserEmailInput } from './UserEmailInput';
import { UserPasswordInput } from './UserPasswordInput';

const defaultValues =
  process.env.NODE_ENV === 'development'
    ? {
        email: 'planergia@uniqueapps.dev',
        password: 'dupa123',
      }
    : {
        email: '',
        password: '',
      };

export const SignInForm = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const methods = useForm<AuthorizationSchemaType>({
    resolver: yupResolver(AuthorizationSchema({ t })),
    defaultValues,
  });

  const onSubmit = async (data: AuthorizationSchemaType) => {
    setLoading(true);
    await dispatch(signInUser(data));
    dispatch(push('/dashboard'));
    setLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <UserEmailInput disabled={loading} />
        <UserPasswordInput disabled={loading} />
        <Box mt={4}>
          <ButtonStyled
            loading={loading}
            type="submit">
            {t('Sign In')}
          </ButtonStyled>
        </Box>
      </form>
    </FormProvider>
  );
};

const ButtonStyled = styled(Button)`
  display: flex;
  width: 100%;
`;
