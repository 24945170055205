import { Icon } from '@common/Icon/Icon';
import { ContentBox } from '@common/Layout/Content/ContentBox';
import { ContentHeader } from '@common/Layout/Content/ContentHeader';
import { ContentNavbar } from '@common/Layout/Content/ContentNavbar';
import { SearchInput } from '@common/List/Actions/Search/SearchInput';
import { Table } from '@common/Table/Table';
import { TableActionLink } from '@common/Table/TableActionLink';
import { Paragraph } from '@common/Typography/Paragraph';
import { useApi } from '@hooks/useApi';
import { useList } from '@hooks/useList';
import { listUsers } from '@services/api/users';
import { getPermissionName, UserPermission, UserType } from '@services/model/user';
import { capitalize, searchAll } from '@utils/fp';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RemoveUserItem } from '../utils/RemoveUserItem';
import { AddUserModal } from './AddUserModal/AddUserModal';

const headers = {
  firstname: {
    label: t('First name'),
    value: capitalize,
  },
  lastname: {
    label: t('Last name'),
    value: capitalize,
  },
  email: {
    label: t('Email address'),
  },
  commune: {
    label: t('Commune'),
    value: capitalize,
  },
  permission: {
    label: t('System role'),
    value: (value: string) => getPermissionName(value as unknown as UserPermission),
  },
};

export const UsersListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const {
    data: body = [],
    call,
    isLoading,
  } = useApi({
    method: listUsers,
    initialLoading: true,
  });

  const { data, visible, rootRef, onSearchChange } = useList<UserType, HTMLDivElement>({
    data: body,
    match: searchAll,
  });

  useEffect(() => {
    call();
  }, [call]);

  return (
    <>
      <ContentHeader
        title={t('Manage users')}
        actions={<AddUserModal onSuccess={() => call()} />}
        subtitle={t('Users management view. Allow to create and configure users which have access to system')}
      />
      <ContentNavbar>
        <SearchInput
          placeholder={t('Type to search')}
          onChange={onSearchChange}
        />
        <Paragraph space={0}>
          {t('Total {{visible}} of {{total}}', {
            total: body.length,
            visible,
          })}
        </Paragraph>
      </ContentNavbar>
      <ContentBox
        table
        loading={isLoading}>
        <Table
          forwardRef={rootRef}
          headers={headers}
          data={data}
          leadingActions={({ item }) => (
            <TableActionLink
              icon={<Icon name="edit" />}
              text={t('Edit user')}
              to={`/users/${item.id}/edit`}
            />
          )}
          actions={({ item }) => (
            <>
              {/* <DropdownLinkItem
                icon={<Icon name="edit" />}
                text={t('Edit user')}
                to={`/users/${item.id}/edit`}
              /> */}
              <RemoveUserItem
                id={item.id}
                onSuccess={() => {
                  call();
                }}
              />
            </>
          )}
        />
      </ContentBox>
    </>
  );
};
