import { createContext, useContext } from 'react';

import { AnySchema } from './useJsonSchema';
import { isRequired, resolveSchema } from './utils';

type FormContextProps = {
  schema: AnySchema;
};

export const FormContext = createContext<FormContextProps>({} as FormContextProps);

export const useFormContext = () => {
  const { schema } = useContext(FormContext);

  return {
    schema,
    resolveRef: resolveSchema(schema),
    isRequired: isRequired(schema),
  };
};
