import { routes as buckets } from '@screens/Bucket/routes';
import { routes as clients } from '@screens/Clients/routes';
import { routes as dashboard } from '@screens/Dashboard/routes';
import { SignInPage } from '@screens/SignInPage';
import { SignOutPage } from '@screens/SignOutPage';
import { routes as user } from '@screens/Users/routes';
import { UserPermission } from '@services/model/user';
import { Outlet } from 'react-router';

import { RouteGroup } from './type';

export const routes: RouteGroup[] = [
  dashboard,
  user,
  clients,
  buckets,
  {
    path: '/',
    component: Outlet,
    routes: [
      {
        path: 'login',
        permission: UserPermission.GUEST,
        component: SignInPage,
      },
      {
        path: 'logout',
        permission: UserPermission.GUEST,
        component: SignOutPage,
      },
    ],
  },
];
