import { css, Theme } from '@mui/material/styles';

export const ScrollbarsCss = ({ theme }: { theme: Theme }) => css`
  scrollbar-color: ${theme.palette.grey[600]} ${theme.palette.grey[200]};
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.palette.grey[600]};
  }

  &::-webkit-scrollbar-track {
    background: ${theme.palette.grey[200]};
  }
`;
