import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import { ComponentType } from 'react';

export const isSame =
  <T>(a: T) =>
  (b: T) =>
    b === a;

export const extract = <T extends Record<string, any>>(object: T, prop: string) => object[prop];

export const isSameAsProp =
  <T extends Record<string, any>, P = any>(prop: string, a: P) =>
  (object: T) =>
    object[prop] === a;

export const not =
  (cb: (...args: any[]) => boolean) =>
  (...args: any[]) =>
    !cb(...args);

export const last = <
  T extends RelativeIndexable<any> & {
    length: number;
  },
>(
  a: T,
) => a.at(a.length - 1);

export const first = <T extends RelativeIndexable<any>>(a: T) => a.at(0);

export const uppercase = (a: string) => a.toLocaleUpperCase();

export const capitalize = (a: string) => a.charAt(0).toUpperCase() + a.substring(1);

export const namedLazyImport =
  <T = any>(m: string, c: string) =>
  () =>
    import(`${m}`).then((module) => ({
      default: module[c] as ComponentType<T>,
    }));

export const prependArg =
  (call: Function, arg: any) =>
  (...args: any) =>
    call(arg, ...args);

export const join = (...args: Array<string | undefined>) => args.filter(Boolean).join(' ');

export const isSimilarString = (a: string, b: string): boolean => a.toLocaleLowerCase().includes(b.toLocaleLowerCase());

export const searchAll = (query: string, a: Record<string, any>) =>
  Object.values(a).some((item) => typeof item === 'string' && isSimilarString(item, query));

export const wordize = (camelCaseString: string) =>
  camelCaseString
    .replaceAll(/_/g, ' ')
    .replaceAll(/[A-Z]/g, (sub) => ` ${sub}`)
    .trim();

export const orderBy = <T extends Record<string, any>>(iterable: T[], property: keyof T): T[] => {
  const next = [...iterable];

  next.sort((a: T, b: T) => {
    const { [property]: left } = a;
    const { [property]: right } = b;

    if (typeof left === 'string' && typeof right === 'string') {
      return left.toLowerCase().localeCompare(right.toLowerCase());
    }

    return left > right ? 1 : -1;
  });

  return next;
};

export const formatDate = (iso: string) =>
  format(parseISO(iso), 'P', {
    locale: pl,
  });

export const formatTime = (iso: string) =>
  format(parseISO(iso), 'p', {
    locale: pl,
  });

export const dirname = (path: string) => {
  const slugs = path.split('/');

  return slugs.slice(0, slugs.length - 1).join('/');
};
