import { Dropdown } from '@common/Dropdown/Dropdown';
import { styled } from '@mui/material/styles';
import { WithForwardRef } from '@utils/types';
import { useTranslation } from 'react-i18next';

type TableHeaderType = {
  label: string;
  value?: (value: any) => React.ReactNode;
};

type TableHeaders = Record<string, TableHeaderType>;
type TableItem<H extends TableHeaders> = {
  [Key in keyof H]: any;
} & {
  id: string;
};

type TableActionRendererProps<D> = {
  item: D;
  onClose: (event: Event | React.SyntheticEvent) => void;
};

type TableProps<Header extends TableHeaders, Data extends TableItem<Header>> = WithForwardRef<
  {
    headers: Header;
    data: Array<Data>;
    actions?: React.ElementType<TableActionRendererProps<Data>>;
    leadingActions?: React.ElementType<Omit<TableActionRendererProps<Data>, 'onClose'>>;
  },
  HTMLDivElement
>;

export const Table = <Header extends TableHeaders, Data extends TableItem<Header>>({
  headers,
  data,
  actions: Actions,
  leadingActions: LeadingActions,
  forwardRef,
}: TableProps<Header, Data>) => {
  let template = Object.keys(headers);

  if (Actions || LeadingActions) {
    template.push('actions');
  }

  const { t } = useTranslation();

  return (
    <TableBaseStyled ref={forwardRef}>
      <TableHeadStyled template={template}>
        {Object.entries(headers).map(([property, item]) => (
          <TableCellStyled
            key={property}
            area={property}>
            {t(item.label)}
          </TableCellStyled>
        ))}
        {Boolean(Actions) && (
          <TableCellStyled
            area="actions"
            key="actions"
          />
        )}
      </TableHeadStyled>
      {data.map((row, idx) => (
        <TableBodyRowStyled
          key={row.id}
          template={template}>
          {Object.keys(headers).map(
            (property) =>
              typeof headers[property] !== 'undefined' && (
                <TableCellStyled
                  area={property}
                  key={property}>
                  {typeof headers[property].value !== 'undefined'
                    ? // @ts-ignore
                      headers[property].value(row[property])
                    : row[property]}
                </TableCellStyled>
              ),
          )}
          {(typeof Actions !== 'undefined' || typeof LeadingActions !== 'undefined') && (
            <TableCellStyled
              key="actions"
              area="actions">
              {typeof LeadingActions !== 'undefined' && <LeadingActions item={row} />}
              {typeof Actions !== 'undefined' && (
                <Dropdown
                  id={`row-${idx}`}
                  items={({ onClose }) => (
                    <Actions
                      onClose={onClose}
                      item={row}
                    />
                  )}
                />
              )}
            </TableCellStyled>
          )}
        </TableBodyRowStyled>
      ))}
    </TableBaseStyled>
  );
};

const TableBaseStyled = styled('div')`
  display: flex;
  flex-direction: column;
`;

const TableRowStyled = styled('div')<{ template: string[] }>`
  display: grid;
  grid-template: '${(props) => props.template.join(' ')}';
  grid-auto-columns: ${(props) => `calc(100% / ${props.template.length})`};

  margin-bottom: 1rem;
`;

const TableCellStyled = styled('div')<{ area: string }>`
  display: flex;
  grid-area: ${(props) => props.area};

  align-items: center;

  ${(props) =>
    props.area === 'actions' &&
    `
    justify-content: end;
    gap: 0.5rem;
  `}
`;

const TableBodyRowStyled = styled(TableRowStyled)`
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;

  filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.05));

  ${TableCellStyled} {
    background: #fff;
    padding: 1.5rem 1rem;

    border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }

  ${TableCellStyled}:first-of-type {
    border-left: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }

  ${TableCellStyled}:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }
`;

const TableHeadStyled = styled(TableRowStyled)`
  background: #fff;
  border-top: unset;
  padding: 0 1rem;

  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};

  position: sticky;
  top: 0;

  z-index: 1;

  ${TableCellStyled} {
    padding: 1rem 1rem;
    font-weight: 500;
  }
`;
