import { ColorVariants } from '@common/types';
import { styled } from '@mui/material';
import {
  FiCheckSquare,
  FiChevronDown,
  FiChevronUp,
  FiDownload,
  FiEdit,
  FiFile,
  FiFileText,
  FiImage,
  FiMail,
  FiMoreVertical,
  FiSearch,
  FiSquare,
  FiTrash,
  FiUpload,
  FiX,
} from 'react-icons/fi';
import { SlPhone, SlScreenDesktop } from 'react-icons/sl';

const icons = {
  close: FiX,
  remove: FiTrash,
  edit: FiEdit,
  search: FiSearch,
  selected: FiCheckSquare,
  deselected: FiSquare,
  chevronDown: FiChevronDown,
  chevronUp: FiChevronUp,
  image: FiImage,
  file: FiFile,
  document: FiFileText,
  more: FiMoreVertical,
  download: FiDownload,
  upload: FiUpload,
  desktop: SlScreenDesktop,
  phone: SlPhone,
  mail: FiMail,
};

type IconColors = ColorVariants | 'contained';

export type IconTypes = keyof typeof icons;

type IconProps = {
  name: IconTypes;
  color?: IconColors;
  width?: string;
  height?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

export const Icon: React.FC<IconProps> = ({ name, hidden = false, color = 'contained', width, height, ...props }) => {
  const Component = icons[name];

  return (
    <IconStyled
      {...props}
      color={color}
      hidden={hidden}
      clickable={Boolean(props.onClick)}>
      <Component size={width} />
    </IconStyled>
  );
};

const IconStyled = styled('span')<{ hidden: boolean; clickable: boolean; color: IconColors }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.color !== 'contained' &&
    `
    svg  {
      stroke: ${props.theme.palette[props.color].main};
    } 
  `}

  ${(props) =>
    props.hidden &&
    `
    pointer-events: none;
    opacity: 0;
  `}

  ${(props) =>
    props.clickable &&
    `
   
    svg:hover {
      cursor: pointer;
    }
  `}
`;
