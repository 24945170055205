import { useToggle } from '@hooks/useToggle';

import { Drawer, DrawerChildProps, DrawerHandlerProps, DrawerProps } from './Drawer';

type UncontrolledDrawerProps = Omit<DrawerProps, 'isOpen' | 'onClose' | 'onOpen' | 'children'> & {
  handler: (props: DrawerHandlerProps) => React.ReactNode;
  children: (props: DrawerChildProps) => React.ReactNode;
};

export const UncontrolledDrawer: React.FC<UncontrolledDrawerProps> = ({ handler, children, ...props }) => {
  const {
    state: isOpen,
    on: onOpen,
    off: onClose,
  } = useToggle({
    initialState: false,
  });

  return (
    <>
      {handler({ onOpen })}
      <Drawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        {...props}>
        {children({ onClose })}
      </Drawer>
    </>
  );
};
