import CreateTodoItemSchema from '@assets/schema/client/todo.json';
import { Button } from '@common/Button/Button';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { TextArea } from '@common/Form/TextArea';
import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import { Box, Grid, styled } from '@mui/material';
import { createClientToDo } from '@services/api/client';
import { selectUser } from '@store/user/selectors';
import { format } from 'date-fns';
import { merge } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useClientContext } from '../../Tabs/useClientTabsContext';
import { ToDoItem } from '../types';
import { TitleField } from './TitleField';

type ToDoListCreateModalBodyProps = {
  onClose: () => void;
  type: string;
};

type ToDoItemType = Record<string, string> & {
  group: string;
};

export const ToDoListCreateModalBody: React.FC<ToDoListCreateModalBodyProps> = ({ onClose, type }) => {
  const { t } = useTranslation();
  const { firstname, lastname } = useSelector(selectUser);
  const { client, patch } = useClientContext();

  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: CreateTodoItemSchema as unknown as SchemaObject,
    defaultValues: {
      group: type,
      owner: `${firstname} ${lastname}`,
    },
  });

  const { call, isLoading } = useApi({
    method: createClientToDo,
    initialLoading: false,
    onError: onErrorEvent,
    onSuccess: useCallback<UseApiSuccessEvent<ToDoItem>>(
      ({ data, onSuccessNotification }) => {
        onSuccessNotification(t('New recommendation added'));

        patch((client) => {
          const next = merge({}, client);

          (next[type] as ToDoItem[]).push(data);

          return next;
        });

        onClose();
      },
      [t, type, patch, onClose],
    ),
  });

  const onSubmit = ({ group, ...data }: ToDoItemType) => {
    call(group, client.id, data);
  };

  const { watch, setValue } = controls;

  const isCompleted = watch('completed');

  useEffect(() => {
    if (!isCompleted) {
      setValue('completionTime', undefined, {
        shouldValidate: true,
      });
    } else {
      const today = format(new Date(), 'yyyy-MM-dd');

      setValue('completionTime', today, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [isCompleted, setValue]);

  return (
    <FormProvider {...controls}>
      <form
        onSubmit={controls.handleSubmit(onSubmit)}
        noValidate>
        <Form schema={schema}>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}>
              <FormControl path="group" />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="type"
                control={TitleField}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl path="owner" />
            </Grid>
            <Grid
              item
              xs={6}>
              <FormControl path="completed" />
            </Grid>
            <Grid
              item
              xs={6}>
              <FormControl
                path="completionTime"
                lang="pl-PL"
                disabled={!isCompleted}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="description"
                control={TextArea}
              />
            </Grid>
          </Grid>

          <StyledButtonWrapper mt={5}>
            <Button
              loading={isLoading}
              disabled={!controls.formState.isValid}
              type="submit"
              color="primary">
              {t('Save')}
            </Button>
          </StyledButtonWrapper>
        </Form>
      </form>
    </FormProvider>
  );
};

const StyledButtonWrapper = styled(Box)`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
