import { SelectInputRemote, SelectInputRemoteProps } from '@common/Form/SelectInputRemote';
import { fetchVoivodeships } from '@services/api/administrative';
import { WithoutForwardRef } from '@utils/types';
import { forwardRef } from 'react';

const VoivodeshipFieldComponent: React.FC<SelectInputRemoteProps> = (props) => (
  <SelectInputRemote
    {...props}
    call={fetchVoivodeships}
  />
);

export const VoivodeshipField = forwardRef<HTMLInputElement, WithoutForwardRef<SelectInputRemoteProps>>(
  (props, ref) => (
    <VoivodeshipFieldComponent
      {...props}
      forwardRef={ref}
    />
  ),
);
