import { Renderer } from '@common/Routes/Renderer';
import { SnackbarContainer } from '@common/SnackbarContainer';
import { Spinner } from '@common/Spinner/Spinner';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ErrorPage } from '@screens/ErrorPage';
import { i18n } from '@services/i18n';
import { routes } from '@services/routes/routes';
import { theme } from '@services/theme';
import { createStore } from '@store/createStore';
import { createBrowserHistory } from 'history';
import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const history = createBrowserHistory();
const store = createStore(history);
const persistor = persistStore(store);

const App: React.FC<{}> = () => (
  <>
    <CssBaseline enableColorScheme />
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ReduxRouter
            history={history}
            store={store}>
            <PersistGate
              loading={null}
              persistor={persistor}>
              <Suspense fallback={<Spinner centered />}>
                <Renderer
                  routes={routes}
                  default={<ErrorPage />}
                />

                <SnackbarContainer />
              </Suspense>
            </PersistGate>
          </ReduxRouter>
        </Provider>
      </I18nextProvider>
    </ThemeProvider>
  </>
);

export default App;
