import background, { ReactComponent as ErrorBackgroundComponent } from '@assets/background/404.svg';
import { css } from '@mui/material/styles';

export const ErrorBackgroundCss = css`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center left;
  width: 100%;
  height: 100%;
`;

export { ErrorBackgroundComponent };
