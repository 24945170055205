import { Button } from '@common/Button/Button';
import { IconButton } from '@common/Button/IconButton';
import { Icon } from '@common/Icon/Icon';
import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import { usePermission } from '@hooks/usePermission';
import { removeClientToDo } from '@services/api/client';
import { merge } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useClientContext } from '../Tabs/useClientTabsContext';

type ToDoListItemRemoveBtnProps = {
  id: string;
  type: 'meetings' | 'buildingActions' | 'actions';
  onSuccess?: () => void;
  title?: string;
};

export const ToDoListItemRemoveBtn: React.FC<ToDoListItemRemoveBtnProps> = ({ id, type, onSuccess, title }) => {
  const { t } = useTranslation();
  const { client, patch } = useClientContext();
  const { canWrite } = usePermission();

  const { call, isLoading } = useApi({
    method: removeClientToDo,
    onSuccess: useCallback<UseApiSuccessEvent<void>>(
      ({ onSuccessNotification }) => {
        onSuccessNotification(t('Action has been removed'));

        if (onSuccess) {
          onSuccess();
        }
      },
      [t, onSuccess],
    ),
  });

  const onClick = () => {
    call(client.id, type, id).then(() => {
      patch((client) => {
        const next = merge({}, client);

        next[type] = next[type].filter((item) => item.id !== id);

        return next;
      });
    });
  };

  if (!canWrite()) {
    return null;
  }

  if (title) {
    return (
      <Button
        onClick={onClick}
        loading={isLoading}
        color="error">
        {title}
      </Button>
    );
  }

  return (
    <IconButton
      onClick={onClick}
      loading={isLoading}
      color="contained"
      icon={<Icon name="remove" />}
    />
  );
};
