import { Icon } from '@common/Icon/Icon';
import { TableActionLink } from '@common/Table/TableActionLink';
import { selectToken } from '@store/client/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const DownloadLink: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();

  const token = useSelector(selectToken);

  const onClose = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const uri = new URL(document.location.toString());
    uri.pathname = `/api/bucket/default/${id}/download`;
    uri.searchParams.append('token', token);

    document.location = uri.toString();
  };

  return (
    <TableActionLink
      text={t('Download')}
      icon={<Icon name="download" />}
      onClick={onClose}
    />
  );
};
