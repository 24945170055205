import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from '@services/model/notify';
import { isSameAsProp, not } from '@utils/fp';

import { initialState } from './initialState';
import { error as createError, requestId, success as createSuccess } from './utils';

export const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    push(state, action: PayloadAction<Omit<NotificationType, 'id'>>) {
      state.push(requestId(action.payload));
    },
    pop(state, action: PayloadAction<NotificationType['id']>) {
      return state.filter(not(isSameAsProp('id', action.payload)));
    },
  },
});

export const { push, pop } = notifySlice.actions;

export const success = (msg: string) => push(createSuccess(msg));
export const error = (msg: string) => push(createError(msg));

export const { reducer } = notifySlice;
