import { api } from '@services/api/utils';
import { AuthorizationSchemaType } from '@services/schemas/auth';
import { initializeApp } from 'firebase/app';
import {
  browserSessionPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut as signOutViaFirebase,
  updatePassword,
  User,
} from 'firebase/auth';

import { firebase } from '../config';
import { SignedInUser } from './types';

const app = initializeApp(firebase.options);

const auth = getAuth(app);

setPersistence(auth, browserSessionPersistence);

if (firebase.authEmulator) {
  connectAuthEmulator(auth, firebase.authEmulator);
}

type PartiallySignedInUser = Omit<SignedInUser, 'user'>;

const token = async (user: User) => await user.getIdToken(false);

export const signIn = async ({ email, password }: AuthorizationSchemaType): Promise<PartiallySignedInUser> => {
  const { user } = await signInWithEmailAndPassword(auth, email, password);

  return {
    _cm: user,
    client: async () => api(await token(user)),
    token: await token(user),
  };
};

export { updatePassword };

export const signOut = async () => signOutViaFirebase(auth);
