import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { History } from 'history';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage/session';

import { reducer as client } from './client/index';
import { reducer as notify } from './notify/index';
import { reducer as user } from './user/index';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['notify', 'client'],
};

const createStore = (history: History) =>
  configureStore({
    reducer: persistReducer(
      persistConfig,
      combineReducers({
        // @ts-ignore
        router: createRouterReducer(history),
        client,
        user,
        notify,
      }),
    ),
    // @ts-ignore
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createRouterMiddleware(history)),
  });

export { createStore };
