import { Button } from '@common/Button/Button';
import { UncontrolledDrawer } from '@common/Drawer/UncontrolledDrawer';
import { Paragraph } from '@common/Typography/Paragraph';
import { usePermission } from '@hooks/usePermission';
import { useTranslation } from 'react-i18next';

import { AddClientModalForm } from './AddClientModalForm';

type AddClientModalProps = {
  onSuccess: () => void;
};

export const AddClientModal: React.FC<AddClientModalProps> = ({ onSuccess }) => {
  const [t] = useTranslation();

  const title = t('Add client');
  const { canWrite } = usePermission();

  if (!canWrite()) {
    return null;
  }

  return (
    <UncontrolledDrawer
      title={title}
      cancelable
      handler={({ onOpen }) => (
        <Button
          onClick={onOpen}
          color="primary">
          {title}
        </Button>
      )}>
      {({ onClose }) => (
        <>
          <Paragraph space>
            {t('Client creation view. Here you can create a Client which will be proceed in system')}
          </Paragraph>
          <AddClientModalForm
            onSuccess={() => {
              onSuccess();
              onClose();
            }}
          />
        </>
      )}
    </UncontrolledDrawer>
  );
};
