import { Spinner } from '@common/Spinner/Spinner';
import { Heading } from '@common/Typography/Heading';
import { styled } from '@mui/material/styles';

type FormHeaderProps = {
  title: string;
  loading?: boolean;
};

export const FormHeader = ({ title, loading }: FormHeaderProps) => (
  <FormHeaderStyled>
    <Heading
      size={6}
      gutterBottom={false}>
      {title}
    </Heading>
    {loading && <Spinner size="1rem" />}
  </FormHeaderStyled>
);

const FormHeaderStyled = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
