import background, { ReactComponent as AppLogoComponent } from '@assets/logos/logo.svg';
import { css } from '@mui/material/styles';

export { AppLogoComponent };

export const AppLogo = css`
  position: absolute;
  content: '';
  color: #fff;

  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: 25% 25%;
  width: 50vw;
  height: 50vw;

  max-width: 35em;
  max-height: 35em;

  top: 0;
  left: 0;

  border-bottom-right-radius: 100%;
`;
