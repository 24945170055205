import { Paragraph } from '@common/Typography/Paragraph';
import { usePermission } from '@hooks/usePermission';
import TouchRipple, { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { styled } from '@mui/material/styles';
import { wordize } from '@utils/fp';
import { WithStyle } from '@utils/types';
import { format } from 'date-fns';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ToDoListItemRemoveBtn } from './ToDoListItemRemoveBtn';
import { TodoListItemStatusToggle } from './TodoListItemStatusToggle';
import { ToDoItem } from './types';
import { ToDoListUpdateModalLink } from './UpdateModal/ToDoListUpdateModalLink';
import { ToDoItemSchemaValues } from './useToDoListItemForm';

export type ToDoListItemProps = {
  item: ToDoItem;
  type: 'meetings' | 'buildingActions' | 'actions';
  onSubmit: (values: ToDoItemSchemaValues) => void;
};

export const ToDoListItem: React.FC<WithStyle<ToDoListItemProps>> = ({ type, item, onSubmit }) => {
  const rippleRef = useRef<TouchRippleActions>();

  const onMouseDown = (e: any) => rippleRef?.current?.start(e);
  const onMouseUp = (e: any) => rippleRef?.current?.stop(e);

  const { t } = useTranslation();
  const { canWrite } = usePermission();

  const submit = useCallback(
    (values: ToDoItem) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  const onChangeCompletion = useCallback(
    (completed: boolean) => {
      submit({
        ...item,
        completionTime: completed ? format(new Date(), 'yyyy-MM-dd') : '',
        completed,
      });
    },
    [submit, item],
  );

  return (
    <ToDoListItemStyled completed={item.completed}>
      <ToDoListItemHeader>
        <ToDoListLabel
          htmlFor={`status-${item.id}`}
          disabled={!canWrite()}
          onMouseOut={onMouseUp}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}>
          <TodoListItemStatusToggle
            id={`status-${item.id}`}
            completed={item.completed}
            onChange={onChangeCompletion}
            disabled={!canWrite()}
          />
          <ToDoListItemTitle
            size={3}
            space={0}>
            {t(wordize(item.title))}
          </ToDoListItemTitle>
          {canWrite() && (
            <TouchRipple
              ref={rippleRef}
              center={false}
            />
          )}
        </ToDoListLabel>
        <ToDoListActions>
          <ToDoListUpdateModalLink
            item={item}
            type={type}
          />
          <ToDoListItemRemoveBtn
            id={item.id}
            type={type}
          />
        </ToDoListActions>
      </ToDoListItemHeader>
    </ToDoListItemStyled>
  );
};

const ToDoListItemHeader = styled('div')`
  display: flex;
  flex: 1;

  flex-direction: row;
  align-items: center;
  gap: 1rem;

  padding-right: 1rem;

  position: relative;
  user-select: none;
`;

const ToDoListLabel = styled(`label`)<{ disabled: boolean }>`
  display: flex;
  flex: 1;
  justify-content: start;
  position: relative;
  padding: 1rem;
  ${(props) =>
    !props.disabled &&
    `
  cursor: pointer;
  `}

  margin-left: auto;
`;

const ToDoListItemTitle = styled(Paragraph)`
  margin-right: 0;
  text-align: left;
`;

const ToDoListItemStyled = styled('li')<{ completed?: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 1.05rem;

  &:hover ${ToDoListItemHeader} {
    cursor: pointer;
  }

  ${ToDoListItemHeader} {
    ${(props) =>
      props.completed &&
      `
      opacity: .75;
    `}
  }

  ${ToDoListItemTitle} {
    ${(props) => props.completed && `text-decoration: line-through; `}
  }
`;

const ToDoListActions = styled('div')``;
