import { SelectInputRemote, SelectInputRemoteProps } from '@common/Form/SelectInputRemote';
import { fetchCommunes } from '@services/api/administrative';
import { ApiType } from '@services/api/utils';
import { WithoutForwardRef } from '@utils/types';
import { forwardRef, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

const CommuneFieldComponent: React.FC<SelectInputRemoteProps> = (props) => {
  const { watch } = useFormContext();

  const voivodeship = watch('county');

  const call = useCallback(
    (api: ApiType) => {
      const fetch = fetchCommunes(api);

      return () => fetch(voivodeship);
    },
    [voivodeship],
  );

  return (
    <SelectInputRemote
      {...props}
      call={call}
    />
  );
};

export const CommuneField = forwardRef<HTMLInputElement, WithoutForwardRef<SelectInputRemoteProps>>((props, ref) => (
  <CommuneFieldComponent
    {...props}
    forwardRef={ref}
  />
));
