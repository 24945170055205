import { FormField } from '@common/Form/FormField';
import { StringInput } from '@common/Form/StringInput';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type UserEmailInputProps = {
  disabled?: boolean;
};

export const UserEmailInput = ({ disabled }: UserEmailInputProps) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="email"
      render={({ field, fieldState }) => (
        <FormField
          {...field}
          disabled={disabled}
          isDirty={fieldState.isDirty}
          isTouched={fieldState.isTouched}
          error={fieldState.error}
          placeholder={t('Please enter your e-mail address')}
          control={StringInput}
          label={t('E-mail address')}
        />
      )}
    />
  );
};
