import { PlanergiaLogo } from '@common/Assets/PlanergiaLogo';
import { Nav } from '@common/Nav/Nav';
import { NavGroup } from '@common/Nav/NavGroup';
import { UserInfoPanel } from '@common/UserInfoPanel';
import { Box, styled } from '@mui/material';
import { nav, userNav } from '@services/routes/navs';
import { selectUser } from '@store/user/selectors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ChangePassword } from './ChangePassword';
import { CommuneName } from './CommuneName';

const SidebarStyled = styled(Box)`
  grid-area: sidebar;
  border-right: 1px solid ${({ theme }) => theme.palette.grey[300]};

  display: flex;
  flex-direction: column;
`;

export const Sidebar = () => {
  const user = useSelector(selectUser);

  const { t } = useTranslation();
  const navigation = useMemo(() => nav({ t }), [t]);
  const userNavigation = useMemo(() => userNav({ t }), [t]);

  return (
    <SidebarStyled>
      <LogoBlock>
        <PlanergiaLogo
          signet
          variant="filled"
          color="primary"
        />
        <CommuneName />
      </LogoBlock>
      <Box mt="3rem">
        <Nav groups={navigation} />
      </Box>
      <Box mt="auto">
        <UserInfoPanel user={user!}>
          <Nav
            groups={userNavigation}
            noSpace>
            <NavGroup title="Settings">
              <ChangePassword />
            </NavGroup>
          </Nav>
        </UserInfoPanel>
      </Box>
    </SidebarStyled>
  );
};

const LogoBlock = styled(Box)`
  padding: 1rem;
  display: flex;
  gap: 1rem 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;
