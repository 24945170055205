import { PatchFn } from '@hooks/useApi';
import { Client } from '@services/model/client/Client';
import { createContext, useContext } from 'react';

type ClientContextType = {
  client: Client;
  patch: PatchFn<Client>;
};

export const ClientContext = createContext<ClientContextType>({
  client: {} as Client,
  patch: () => {},
});

export const useClientContext = () => useContext(ClientContext);
