import { createSelector } from '@reduxjs/toolkit';
import { UserPermission } from '@services/model/user';
import { RootState } from '@store/types';

const generic = (state: RootState) => state.user;

export const selectUser = createSelector(generic, (state) => state.user);

export const selectUserPermission = createSelector(
  generic,
  (state) => state.user?.permission ?? UserPermission.NOT_ALLOWED,
);
