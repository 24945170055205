import { IconButton } from '@common/Button/IconButton';
import { Icon } from '@common/Icon/Icon';
import { push } from '@lagunovsky/redux-react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { ToDoItem } from '../types';

type ToDoListUpdateModalLinkProps = {
  item: ToDoItem;
  type: string;
  className?: string;
};

export const ToDoListUpdateModalLink: React.FC<ToDoListUpdateModalLinkProps> = ({ item, className, type }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(push(`${pathname}/${type}/${item.id}`));
  };

  return (
    <IconButton
      onClick={onClick}
      icon={<Icon name="edit" />}
      disabled={pathname.includes('todo')}
      className={className}
      color="contained"
    />
  );
};
