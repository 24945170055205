import { DropdownItem } from '@common/Dropdown/Item';
import { Icon } from '@common/Icon/Icon';
import { Spinner } from '@common/Spinner/Spinner';
import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import { deleteBucketFile } from '@services/api/buckets';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type RemoveFileItemProps = {
  id: string;
  onSuccess: () => void;
};

export const RemoveFileItem: React.FC<RemoveFileItemProps> = ({ id, onSuccess }) => {
  const { t } = useTranslation();
  const { call, isLoading } = useApi({
    initialLoading: false,
    method: deleteBucketFile,
    onSuccess: useCallback<UseApiSuccessEvent<void>>(
      ({ onSuccessNotification }) => {
        onSuccessNotification(t('Material has been removed'));
        onSuccess();
      },
      [t, onSuccess],
    ),
  });

  const onRemove = () => call('default', id);

  return (
    <DropdownItem
      onClose={onRemove}
      text={t('Remove file')}
      icon={isLoading ? <Spinner size={16} /> : <Icon name="remove" />}
    />
  );
};
