import { Spinner } from '@common/Spinner/Spinner';
import { Heading } from '@common/Typography/Heading';
import { useApi } from '@hooks/useApi';
import { styled } from '@mui/material/styles';
import { updateClientToDo } from '@services/api/client';
import { get, merge } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useClientContext } from '../Tabs/useClientTabsContext';
import { mapMeetings } from '../utils';
import { ToDoListCreateModalLink } from './CreateModal/ToDoListCreateModalLink';
import { ToDoListItem } from './ToDoListItem';
import { ToDoItem } from './types';
import { ToDoItemSchemaValues } from './useToDoListItemForm';
import { countCompleted } from './utils';

export type ToDoListProps = {
  property: 'meetings' | 'buildingActions' | 'actions';
  title: string;
};

export const ToDoList: React.FC<ToDoListProps> = ({ property, title }) => {
  const { client, patch } = useClientContext();
  const { t } = useTranslation();

  const items = useMemo<ToDoItem[]>(() => mapMeetings(get(client, property, [])), [client, property]);

  const { call, isLoading } = useApi({
    method: updateClientToDo,
    initialLoading: false,
    onSuccess: useCallback(
      ({ data }: { data: ToDoItem }) => {
        patch((client) => {
          const nextData = merge({}, client, {
            [property]: get(client, property, []).map((item: ToDoItem) => (item.id === data.id ? data : item)),
          });

          return nextData;
        });
      },
      [patch, property],
    ),
  });

  const onSubmit = useCallback(
    (meeting: ToDoItemSchemaValues) => {
      call(property, client.id, meeting);
    },
    [call, client.id, property],
  );

  return (
    <ToDoListWrapper>
      <ToDoListHeader>
        <Heading
          size={6}
          gutterBottom={false}>
          {title}
        </Heading>
        <ToDoListCounter loading={isLoading}>
          <ToDoListSpinner size="1rem" />
          <ToDoListCounterContent>{`${countCompleted(items)} / ${items.length}`}</ToDoListCounterContent>
        </ToDoListCounter>
      </ToDoListHeader>
      <ToDoListStyled>
        {items.map((item) => (
          <ToDoListItem
            type={property}
            onSubmit={onSubmit}
            item={item}
            key={item.id}
          />
        ))}
      </ToDoListStyled>
      <StyledToDoListCreateModalLink
        color="contained"
        type={property}
        title={t('Add new recommendation')}
      />
    </ToDoListWrapper>
  );
};

const StyledToDoListCreateModalLink = styled(ToDoListCreateModalLink)`
  border: 1px dashed ${(props) => props.theme.palette.grey[300]};
  margin: 1rem;
`;

const ToDoListStyled = styled('ul')`
  margin: 0;
  padding: 0;
`;

const ToDoListHeader = styled('div')`
  padding: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ToDoListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-bottom: 2rem;
`;

const ToDoListCounterContent = styled('span')``;

const ToDoListSpinner = styled(Spinner)`
  position: absolute;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
`;

const ToDoListCounter = styled(`div`)<{ loading: boolean }>`
  position: relative;

  ${ToDoListSpinner} {
    opacity: 0;
  }

  ${(props) =>
    props.loading &&
    `
    ${ToDoListSpinner} {
      opacity: 1;
    }

    ${ToDoListCounterContent} {
      opacity: 0;
    }
  `}
`;
