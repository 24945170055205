import { styled } from '@mui/material';
import { makeGrid } from '@utils/grid';
import { Range, WithStyle } from '@utils/types';

const sizes = makeGrid(13);

type CardsLayoutProps = {
  children: React.ReactNode;
  horizontal?: boolean;
  size?: Range<1, 13>;
};

export const CardsLayout: React.FC<WithStyle<CardsLayoutProps>> = ({
  children,
  horizontal = false,
  size = 12,
  ...rest
}) => (
  <CardsLayoutStyled
    {...rest}
    horizontal={horizontal}
    width={sizes[size]}>
    {children}
  </CardsLayoutStyled>
);

const CardsLayoutStyled = styled('div')<{ horizontal: boolean; width: string }>`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  gap: 1rem;
  width: ${(props) => props.width};
`;
