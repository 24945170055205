import { LinkButton } from '@common/Button/LinkButton';
import { styled } from '@mui/material';
import { FiPlusSquare } from 'react-icons/fi';
import { useLocation } from 'react-router';

type ToDoListCreateModalLinkProps = {
  type?: string;
  color?: 'primary' | 'contained';
  className?: string;
  title?: string;
};

export const ToDoListCreateModalLink: React.FC<ToDoListCreateModalLinkProps> = ({
  type,
  className,
  color = 'primary',
  title = 'Add',
}) => {
  const { pathname } = useLocation();

  return (
    <StyledLinkButton
      to={`${pathname}/${type}`}
      disabled={pathname.includes('todo')}
      title={title}
      className={className}
      color={color}>
      {title}
      <FiPlusSquare />
    </StyledLinkButton>
  );
};

const StyledLinkButton = styled(LinkButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
`;
