import UpdateUserSchema from '@assets/schema/user/update.json';
import { Button } from '@common/Button/Button';
import { Card } from '@common/Card/Card';
import { CardsLayout } from '@common/Card/CardsLayout';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { ReadonlyFormControl } from '@common/Form/ReadonlyFormControl';
import { ContentBox } from '@common/Layout/Content/ContentBox';
import { ContentHeader } from '@common/Layout/Content/ContentHeader';
import { ListItemLine } from '@common/List/ListItemLine';
import { Paragraph } from '@common/Typography/Paragraph';
import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import { replace } from '@lagunovsky/redux-react-router';
import { Grid } from '@mui/material';
import { CommuneField } from '@screens/Clients/common/CommuneField';
import { VoivodeshipField } from '@screens/Clients/common/VoivodeshipField';
import { getUser, updateUser } from '@services/api/users';
import { UserType } from '@services/model/user';
import { AppDispatch } from '@store/types';
import { join, prependArg } from '@utils/fp';
import React, { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { RemoveUserButton } from '../utils/RemoveUserButton';

export const UsersEditPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: UpdateUserSchema as unknown as SchemaObject,
    defaultValues: {},
    mode: 'onChange',
  });

  const { reset } = controls;
  const {
    data,
    call,
    isLoading: callingOnFetch,
  } = useApi({
    method: getUser,
    initialLoading: true,
    onSuccess: useCallback<UseApiSuccessEvent<UserType>>(
      ({ data }) => {
        reset(data);
      },
      [reset],
    ),
  });
  const { call: callOnUpdate, isLoading: callingOnUpdate } = useApi({
    method: updateUser,
    initialLoading: false,
    onSuccess: useCallback<UseApiSuccessEvent<UserType>>(
      ({ data, onSuccessNotification }) => {
        onSuccessNotification(
          t('User {{name}} has been updated', {
            name: data.firstname,
          }),
        );
      },
      [t],
    ),
    onError: onErrorEvent,
  });

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      call(userId);
    }
  }, [call, userId]);

  return (
    <FormProvider {...controls}>
      <ContentHeader
        title={t('Edit user {{name}}', {
          name: join(data?.firstname, data?.lastname),
        })}
        actions={
          <>
            <Button
              loading={callingOnUpdate}
              onClick={controls.handleSubmit(prependArg(callOnUpdate, userId))}>
              {t('Save')}
            </Button>
            <RemoveUserButton
              id={userId}
              icon
              onSuccess={() => {
                dispatch(replace(`/users/manage`));
              }}
            />
          </>
        }
        subtitle={t('Single user management view. Here you can change user settings')}
      />
      <ContentBox loading={callingOnFetch}>
        <form noValidate>
          <Form schema={schema}>
            <CardsLayout>
              <Card>
                <ListItemLine heading={t('General user details')}>
                  <Grid
                    container
                    spacing={2}>
                    <Grid
                      item
                      xs={12}>
                      <FormControl path="email" />
                    </Grid>
                    <Grid
                      item
                      md={6}>
                      <FormControl path="firstname" />
                    </Grid>
                    <Grid
                      item
                      md={6}>
                      <FormControl path="lastname" />
                    </Grid>
                  </Grid>
                </ListItemLine>
              </Card>
              <Card>
                <ListItemLine
                  heading={t('System settings')}
                  size={12}>
                  <Grid
                    container
                    spacing={2}>
                    <Grid
                      item
                      xs={6}>
                      <FormControl
                        path="county"
                        control={VoivodeshipField}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}>
                      <FormControl
                        path="commune"
                        control={CommuneField}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}>
                      <FormControl path="permission" />
                    </Grid>
                    <Grid
                      item
                      xs={6}>
                      <ReadonlyFormControl
                        path="password"
                        toggle={({ onToggle }) => (
                          <Paragraph onClick={onToggle}>{t('Click here to change password')}</Paragraph>
                        )}
                      />
                    </Grid>
                  </Grid>
                </ListItemLine>
              </Card>
            </CardsLayout>
          </Form>
        </form>
      </ContentBox>
    </FormProvider>
  );
};
