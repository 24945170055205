import { Icon } from '@common/Icon/Icon';
import { ContentBox } from '@common/Layout/Content/ContentBox';
import { ContentHeader } from '@common/Layout/Content/ContentHeader';
import { ContentNavbar } from '@common/Layout/Content/ContentNavbar';
import { SearchInput } from '@common/List/Actions/Search/SearchInput';
import { Table } from '@common/Table/Table';
import { TableActionLink } from '@common/Table/TableActionLink';
import { Paragraph } from '@common/Typography/Paragraph';
import { useApi } from '@hooks/useApi';
import { useList } from '@hooks/useList';
import { listClients } from '@services/api/client';
import { Client } from '@services/model/client/Client';
import { capitalize, formatDate, searchAll } from '@utils/fp';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AddClientModal } from './AddClientModal/AddClientModal';
import { RemoveClientItem } from './RemoveClientItem/RemoveClientItem';

const headers = {
  firstName: {
    label: t('First name'),
    value: capitalize,
  },
  lastName: {
    label: t('Last name'),
    value: capitalize,
  },
  county: {
    label: t('County'),
    value: capitalize,
  },
  commune: {
    label: t('Commune'),
    value: capitalize,
  },
  street: {
    label: t('Street'),
    value: capitalize,
  },
  house: {
    label: t('House no.'),
  },
  creationTime: {
    label: t('Creation time'),
    value: formatDate,
  },
};

export const ClientsListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const {
    data: body = [],
    call,
    isLoading,
  } = useApi({
    method: listClients,
    initialLoading: true,
  });

  const { data, visible, rootRef, onSearchChange } = useList<Client, HTMLDivElement>({
    data: body,
    match: searchAll,
  });

  useEffect(() => {
    call();
  }, [call]);

  return (
    <>
      <ContentHeader
        title={t('Clients')}
        actions={<AddClientModal onSuccess={() => call()} />}
        subtitle={t('Clients management view')}
      />
      <ContentNavbar>
        <SearchInput
          placeholder={t('Type to search')}
          onChange={onSearchChange}
        />
        <Paragraph space={0}>
          {t('Total {{visible}} of {{total}}', {
            total: body.length,
            visible,
          })}
        </Paragraph>
      </ContentNavbar>
      <ContentBox
        table
        loading={isLoading}>
        <Table
          forwardRef={rootRef}
          headers={headers}
          data={data}
          leadingActions={({ item }) => (
            <TableActionLink
              to={`/clients/${item.id}/edit`}
              text={t('Edit')}
              icon={<Icon name="edit" />}
            />
          )}
          actions={({ item }) => (
            <RemoveClientItem
              id={item.id}
              onSuccess={() => {
                call();
              }}
            />
          )}
          // actions={({ item }) => (
          //   <DropdownLinkItem
          //     to={`/clients/${item.id}/edit`}
          //     text={t('Edit client')}
          //     icon={<Icon name="edit" />}
          //   />
          // )}
        />
      </ContentBox>
    </>
  );
};
