import { ReactComponent as Logo } from '@assets/logos/planergia.svg';
import { ReactComponent as Signet } from '@assets/logos/planergia_signet.svg';
import { ColorVariants, SvgSize } from '@common/types';
import { css, styled } from '@mui/material/styles';
import { Theme } from '@services/theme';
import { useTranslation } from 'react-i18next';

type LogoVariants = 'contained' | 'filled';

type PlanergiaStyleProps = {
  color: ColorVariants;
  variant: LogoVariants;
};

type PlanergiaLogoProps = Partial<PlanergiaStyleProps> & {
  signet?: boolean;
  size?: SvgSize;
  title?: string;
  description?: string;
};

const fill = (theme: Theme, color: ColorVariants, variant: LogoVariants) => css`
  padding: 0.5rem;
  border-radius: ${theme.shape.borderRadius}px;

  ${variant !== 'filled' &&
  `.main {
    fill: ${theme.palette[color].main};
  }`}
  ${variant === 'filled' &&
  `
    background-color: ${theme.palette.grey[900]};
    .main {
      fill: ${theme.palette.common.white};
    }
  `}
`;

const SignetStyled = styled(Signet)<PlanergiaStyleProps>`
  ${({ theme, color, variant }) => fill(theme, color, variant)};
`;

const LogoStyled = styled(Logo)<PlanergiaStyleProps>`
  ${({ theme, color, variant }) => fill(theme, color, variant)};
`;

export const PlanergiaLogo = ({
  signet,
  color = 'primary',
  size = 64,
  variant = 'filled',
  title = 'Planergia',
}: PlanergiaLogoProps) => {
  const { t } = useTranslation();

  if (signet) {
    return (
      <SignetStyled
        title={t(title)}
        width={size}
        height={size}
        color={color}
        variant={variant}
      />
    );
  }

  return (
    <LogoStyled
      title={title}
      height={size}
      color={color}
      variant={variant}
    />
  );
};
