import { styled } from '@mui/material';
import MuiCard from '@mui/material/Card';
import { makeGrid } from '@utils/grid';
import { Range } from '@utils/types';

const sizes = makeGrid(13);

type CardProps = {
  children: React.ReactNode;
  disabled?: boolean;
  padding?: boolean;
  size?: Range<1, 13>;
  full?: boolean;
};

export const Card: React.FC<CardProps> = ({ children, disabled = false, size = 12, padding = true, full = false }) => (
  <CardStyled
    padding={padding}
    width={sizes[size]}
    full={full}
    variant="outlined"
    disabled={disabled}>
    {children}
  </CardStyled>
);

export const CardStyled = styled(MuiCard)<{ disabled: boolean; width: string; padding: boolean; full: boolean }>`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  padding: ${(props) => (props.padding ? '1rem' : '0')};

  width: ${(props) => props.width};
  ${(props) => props.full && `height: 100%`};

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  position: relative;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    user-select: none;

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      content: '';

      background: rgba(255,255,255, 0.75);
    }
  `}
`;
