import { Button } from '@common/Button/Button';
import { UncontrolledDrawer } from '@common/Drawer/UncontrolledDrawer';
import { Paragraph } from '@common/Typography/Paragraph';
import { usePermission } from '@hooks/usePermission';
import { useTranslation } from 'react-i18next';

import { FileUploadForm } from './FileUploadForm';

type FileUploadModalProps = {
  onSuccess: () => void;
};

export const FileUploadModal: React.FC<FileUploadModalProps> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const title = t('Add material');

  const { canAdmin } = usePermission();

  if (!canAdmin()) {
    return null;
  }

  return (
    <UncontrolledDrawer
      title={title}
      cancelable
      handler={({ onOpen }) => (
        <Button
          onClick={onOpen}
          color="primary">
          {title}
        </Button>
      )}>
      {({ onClose }) => (
        <>
          <Paragraph>{t('Upload new guidance material')}</Paragraph>
          <FileUploadForm
            onSuccess={() => {
              onClose();
              onSuccess();
            }}
          />
        </>
      )}
    </UncontrolledDrawer>
  );
};
