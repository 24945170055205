import { usePermission } from '@hooks/usePermission';
import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WithForwardRef } from '@utils/types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Error } from './Error';
import { Label } from './Label';
import { FormFieldType } from './types';

type FormFieldProps<C extends FormFieldType<any>> = C &
  WithForwardRef<
    {
      control: React.ForwardRefExoticComponent<C>;
      label?: string;
      required?: boolean;
      noLabel: boolean;
    },
    HTMLInputElement
  >;

export const FormFieldStyled = styled('div')`
  display: flex;
  flex-direction: column;

  & ~ & {
    margin-top: 1rem;
  }
`;

export const FormFieldComponent = <C extends FormFieldType<any>>({
  name,
  label,
  required,
  error,
  control: Control,
  forwardRef,
  children,
  noLabel,
  ...fieldState
}: FormFieldProps<C> & { children: React.ReactNode }) => {
  const hasError = fieldState.isDirty && !!error?.message;

  const { t } = useTranslation();
  const { canWrite } = usePermission();

  return (
    <FormFieldStyled>
      {!noLabel && (
        <Label
          hasError={hasError}
          id={name}
          required={required}>
          {t(label || name)}
        </Label>
      )}
      <FormControl sx={{ width: '100%' }}>
        <Control
          ref={forwardRef}
          {...{
            ...fieldState,
            name,
            disabled: !canWrite() || fieldState.disabled,
            label: label || name,
            error: hasError ? error : undefined,
          }}
        />
      </FormControl>
      <Error error={hasError ? error : undefined} />
      {children}
    </FormFieldStyled>
  );
};

export const FormField = forwardRef<HTMLInputElement, FormFieldProps<any>>((props, ref) => (
  <FormFieldComponent
    forwardRef={ref}
    {...props}
  />
));
