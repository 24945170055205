import { LinkButton } from '@common/Button/LinkButton';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router';

type TabLinkProp = {
  to: string;
  children: React.ReactNode;
};

export const TabLink: React.FC<TabLinkProp> = ({ to, children }) => {
  const match = useLocation();

  return (
    <LinkButtonStyled
      to={to}
      color="contained"
      active={match.pathname.includes(to)}>
      {children}
    </LinkButtonStyled>
  );
};

const LinkButtonStyled = styled(LinkButton)<{ active: boolean }>`
  & {
    border-radius: unset;
    border-bottom: 2px solid transparent;

    ${(props) =>
      props.active &&
      `
      border-bottom-color: ${props.theme.palette.primary.main}; 
    `}
  }
`;
