import { styled } from '@mui/material/styles';

type TabLinkGroupProps = {
  children: React.ReactNode;
};

export const TabLinkGroup: React.FC<TabLinkGroupProps> = ({ children }) => (
  <TabLinkGroupStyled>{children}</TabLinkGroupStyled>
);

const TabLinkGroupStyled = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: -1px;
`;
