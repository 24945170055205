import { Breakpoint, Theme } from '@mui/material';
import { css } from '@mui/material/styles';
import { SerializedStyles } from '@mui/styled-engine';
import { MUIStyledCommonProps } from '@mui/system';

interface MediaHandler {
  (
    props: MUIStyledCommonProps<Theme> & {
      theme: Theme;
    },
  ): SerializedStyles;
}

export const media =
  (breakpoint: Breakpoint, handler: MediaHandler): MediaHandler =>
  (props) =>
    css`
      ${props.theme.breakpoints.up(breakpoint)} {
        ${handler(props)}
      }
    `;
