import { UserPermission } from '@services/model/user';
import { isAllowed } from '@services/model/user/checks';
import { selectUserPermission } from '@store/user/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NavGroupType } from './types';

type UseNavigationProps = {
  groups: NavGroupType[];
};

const reducer =
  <T extends NavGroupType>(permission: UserPermission) =>
  (carry: T[], group: T) => {
    const items = group.items.filter(
      (item) => !item.permission || (typeof item.permission !== 'undefined' && isAllowed(item.permission, permission)),
    );

    if (items.length > 0) {
      carry.push({
        ...group,
        items: items.map((item) => ({
          ...item,
          path: `${group.path}${item.path}`,
        })),
      });
    }

    return carry;
  };

export const useNavigation = ({ groups }: UseNavigationProps) => {
  const permission = useSelector(selectUserPermission);

  const items = useMemo(() => groups.reduce(reducer(permission), []), [permission, groups]);

  return {
    items,
  };
};
