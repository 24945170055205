import { styled } from '@mui/material';

import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';
import { NavGroupType } from './types';
import { useNavigation } from './useNavigation';

type NavStyledProps = {
  noSpace?: boolean;
};

type NavProps = NavStyledProps & {
  groups: NavGroupType[];
  children?: React.ReactNode;
};

const NavStyled = styled('ul')<NavStyledProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  ${({ noSpace }) => !noSpace && `padding: 3rem 0`}
  display: flex;
  flex-direction: column;
`;

export const Nav = ({ groups, children, ...others }: NavProps) => {
  const { items } = useNavigation({ groups });

  return (
    <NavStyled {...others}>
      {children}
      {items.map(({ items: links, ...group }) => (
        <NavGroup
          {...group}
          key={group.path}>
          {links.map((item) => (
            <NavItem
              {...item}
              key={item.path}
            />
          ))}
        </NavGroup>
      ))}
    </NavStyled>
  );
};
