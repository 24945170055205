import { push as redirect } from '@lagunovsky/redux-react-router';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentUser } from '@services/api/users';
import { withApi } from '@services/api/utils';
import { signIn, signOut } from '@services/auth';
import { i18n } from '@services/i18n';
import { UserType } from '@services/model/user';
import { AuthorizationSchemaType } from '@services/schemas/auth';
import { setClient, setToken } from '@store/client';
import { error, success } from '@store/notify';

import { initialState } from './initialState';

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSignedInUser(state, action: PayloadAction<UserType>) {
      state.user = action.payload;
    },
    setSignedOut(state) {
      state.user = initialState.user;
    },
  },
});

const { setSignedInUser, setSignedOut } = userSlice.actions;

export const signInUser = createAsyncThunk('user/signInUser', async (data: AuthorizationSchemaType, { dispatch }) => {
  try {
    const { client, token } = await signIn(data);

    try {
      const call = await withApi(client, getCurrentUser);
      const user = await call();

      dispatch(setClient(client));
      dispatch(setToken(token));
      dispatch(setSignedInUser(user));
      dispatch(success(i18n.t('You have been signed in!')));

      return user;
    } catch (e) {
      await signOut();
      throw e;
    }
  } catch (e) {
    dispatch(error(i18n.t('Invalid credentials')));
  }
});

export const signOutUser = createAsyncThunk('user/signOut', async (_, { dispatch }) => {
  try {
    await signOut();

    dispatch(setClient(undefined));
    dispatch(setSignedOut());
  } catch (e) {
    dispatch(error(i18n.t('Error during sign out')));
  } finally {
    dispatch(redirect('/login'));
  }
});

export const { reducer } = userSlice;
