import { MenuItem, Select, styled } from '@mui/material';
import { WithForwardRef, WithoutForwardRef } from '@utils/types';
import React, { forwardRef } from 'react';

import { FormFieldType } from './types';

type SelectInputValue = string | number;

export type SelectInputOption = {
  value: SelectInputValue;
  label: string;
};

export type SelectInputProps = WithForwardRef<
  FormFieldType<SelectInputValue> & {
    options: SelectInputOption[];
    startAdornment?: React.ReactNode;
  },
  HTMLInputElement
>;

const SelectInputComponent: React.FC<SelectInputProps> = ({
  placeholder,
  value,
  name,
  error,
  disabled,
  options,
  onBlur,
  onChange,
  forwardRef,
  startAdornment,
}) => (
  <StyledSelect
    onChange={(e) => {
      onChange(e as React.ChangeEvent<HTMLInputElement>);
      window.focus();
    }}
    inputRef={forwardRef}
    error={!!error?.message}
    value={String(value || '')}
    disabled={disabled || options.length === 0}
    {...{ name, placeholder, onBlur, startAdornment }}>
    {options.map((option, idx) => (
      <MenuItem
        autoFocus={false}
        value={String(option.value)}
        key={idx}>
        {option.label}
      </MenuItem>
    ))}
  </StyledSelect>
);

const StyledSelect = styled(Select)`
  ${({ disabled, theme }) =>
    disabled &&
    `
    opacity: 1;
    color: ${theme.palette.grey[500]};

    .MuiInputBase-input,
    .MuiInputBase-input + div {
      // background: ${theme.palette.grey[200]};
    }
  `}
`;

export const SelectInput = forwardRef<HTMLInputElement, WithoutForwardRef<SelectInputProps>>((props, ref) => (
  <SelectInputComponent
    {...props}
    forwardRef={ref}
  />
));
