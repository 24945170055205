import { Paragraph } from '@common/Typography/Paragraph';
import { styled } from '@mui/material';

import { FormFieldError } from './types';

type ErrorProps = {
  error?: FormFieldError;
};

const StyledError = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const Error = ({ error }: ErrorProps) => {
  if (!error?.message) {
    return null;
  }

  return <StyledError>{error?.message}</StyledError>;
};
