import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ArrayField } from '../ArrayField';
import { CheckboxInput } from '../CheckboxInput';
import { ObjectField } from '../ObjectField';
import { SelectInput } from '../SelectInput';
import { StringInput } from '../StringInput';
import { TextArea } from '../TextArea';
import { FormFieldType } from '../types';
import { useFormContext } from './useFormContext';

type UseFormControlProps = {
  path: string;
};

export const useFormControl = ({ path }: UseFormControlProps) => {
  const context = useFormContext();

  const controller = useController({
    name: path,
  });

  const schema = context.resolveRef(path);
  const isRequired = context.isRequired(path);
  const { t } = useTranslation();

  if (!schema) {
    throw new Error(`Field ${path} was improperly resolved, check your schema`);
  }

  const widget = useMemo(() => {
    if (schema.type === 'object') {
      return (props: FormFieldType<any>) => (
        <ObjectField
          schema={schema}
          prefix={path}
        />
      );
    }

    if (schema.type === 'array') {
      return (props: FormFieldType<any>) => (
        <ArrayField
          schema={schema}
          prefix={path}
        />
      );
    }

    if (schema.enum) {
      const options = schema.enum.map((option, idx) => ({
        label: t(String((schema?.enumNames && schema?.enumNames[idx]) ?? option)),
        value: option,
      }));

      return (props: FormFieldType<string | number>) => (
        <SelectInput
          {...props}
          options={options}
        />
      );
    }

    if (schema.type === 'boolean') {
      return ({ value, ...props }: FormFieldType<boolean>) => (
        <CheckboxInput
          checked={Boolean(value)}
          value={value}
          {...props}
        />
      );
    }

    if (schema.format === 'textarea') {
      return (props: FormFieldType<string>) => <TextArea {...props} />;
    }

    return (props: FormFieldType<string>) => (
      <StringInput
        {...props}
        type={schema.format}
      />
    );
  }, [schema, t, path]);

  return {
    ...context,
    ...controller,
    schema,
    isRequired,
    control: widget,
    noLabel: schema.type === 'object',
  };
};
