import { api as ApiConfig } from '@services/config';
import axios, { AxiosResponse } from 'axios';

export const api = (token: string) =>
  axios.create({
    baseURL: `${ApiConfig.apiUrlHost}${ApiConfig.apiUrlPrefix}`,
    timeout: 5000,
    withCredentials: false,
    maxRedirects: 5,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

export type ApiType = ReturnType<typeof api>;
export type ApiHandler<F> = (api: ApiType) => F;

export type ApiCaller = () => Promise<ApiType>;

export type StructuredResponse<T> = {
  data: T;
};

export type StructuredErrorResponse<T = Record<string, any>> = {
  message: string;
  code: number;
  details: T;
};

export const withApi = async <F>(api: ApiCaller, method: ApiHandler<F>) => method(await api());

export const isSchemaError = (error: StructuredErrorResponse): boolean => error.details?.type === 'schema';

export type SchemaError = {
  field: string;
  type: string;
  message: string;
};

export type SchemaErrorResponse = StructuredErrorResponse<{
  type: 'schema';
  errors: SchemaError[];
}>;

export const applyFirstSchemaError = (error: SchemaErrorResponse, use: (error: SchemaError) => void) => {
  const { errors } = error.details || [];

  if (!errors.length) {
    return undefined;
  }

  use(errors[0]);
};

export const pick = <T>(response: StructuredResponse<T>) => response.data;

export const unpack =
  <T>() =>
  (response: AxiosResponse<StructuredResponse<T>>) =>
    pick(response.data);

export const nope = () => (response: AxiosResponse) => {};
