import { Commune, Voivodeship } from '@services/model/client/Administrative';
import { capitalize } from '@utils/fp';
import { AxiosResponse } from 'axios';

import { ApiType, pick, StructuredResponse } from './utils';

type AdministrativeResponse<T> = StructuredResponse<T[]>;

type VoivodeshipResponse = AdministrativeResponse<Voivodeship>;
type CommuneResponse = AdministrativeResponse<Commune>;

const unpackAsOptions = <T extends string>(response: AxiosResponse<AdministrativeResponse<T>>) => {
  const data = pick(response.data);

  return data.map((value) => ({
    label: capitalize(value),
    value,
  }));
};

export const fetchVoivodeships = (api: ApiType) => async () =>
  api.get<VoivodeshipResponse>('administrative/voivodeship').then(unpackAsOptions);

export const fetchCommunes =
  (api: ApiType) =>
  async (voivodeship = '') =>
    api
      .get<CommuneResponse>(voivodeship ? `administrative/voivodeship/${voivodeship}` : 'administrative/commune')
      .then(unpackAsOptions);
