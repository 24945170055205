import { ErrorBackgroundCss } from '@common/Assets/ErrorBackground';
import { PlanergiaLogo } from '@common/Assets/PlanergiaLogo';
import { LinkButton } from '@common/Button/LinkButton';
import { Heading } from '@common/Typography/Heading';
import { Paragraph } from '@common/Typography/Paragraph';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import { useTranslation } from 'react-i18next';

const ErrorPageStyled = styled(Box)`
  ${ErrorBackgroundCss};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

const ErrorPageBox = styled(Box)`
  max-width: 480px;
  width: 100%;
  padding: 2rem 2.75rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 1px 2px 15px 3px rgba(0, 0, 0, 0.1);
`;

export const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <ErrorPageStyled>
      <Box
        position="absolute"
        top="1rem"
        left="1rem">
        <PlanergiaLogo
          signet
          variant="filled"
          size={64}
        />
      </Box>

      <ErrorPageBox>
        <Heading size={4}>{t('Not found')}</Heading>
        <Paragraph space>{t("Resource which you was looking for wasn't found")}</Paragraph>
        <Box mt={4}>
          <LinkButtonStyled to="/dashboard">{t('Back to dashboard')}</LinkButtonStyled>
        </Box>
      </ErrorPageBox>
    </ErrorPageStyled>
  );
};

const LinkButtonStyled = styled(LinkButton)`
  display: flex;
  width: 100%;
`;
