import { NavGroupType } from '@common/Nav/types';
import { UserPermission } from '@services/model/user';
import { TFunction } from 'react-i18next';
// import { FiFolder, FiUsers } from 'react-icons/fi';

type NavServiceProps = {
  t: TFunction<'translation', undefined>;
};

export const nav = ({ t }: NavServiceProps): NavGroupType[] => [
  {
    path: '/',
    items: [
      {
        path: 'clients',
        title: t('Clients'),
        permission: UserPermission.READ,
      },
    ],
  },
  {
    path: '/bucket',
    title: t('Files'),
    // icon: <FiFolder />,
    items: [
      {
        //icon: <FiFolder />,
        path: '/browse',
        title: t('Browse materials'),
        permission: UserPermission.READ,
      },
    ],
  },
  {
    path: '/users',
    items: [
      {
        //icon: <FiUsers />,
        path: '/',
        title: t('Users'),
        permission: UserPermission.ADMIN,
      },
    ],
  },
];

export const userNav = ({ t }: NavServiceProps): NavGroupType[] => [
  {
    path: '/',
    items: [
      {
        path: 'logout',
        title: t('Logout'),
      },
    ],
  },
];
