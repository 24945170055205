import { FormControl } from './FormControl';
import { FormContext } from './hooks/useFormContext';
import { SchemaObject } from './hooks/useJsonSchema';

type FormProps = {
  schema: SchemaObject;
  children?: React.ReactNode;
};

export const Form: React.FC<FormProps> = ({ schema, children }) => (
  <FormContext.Provider value={{ schema }}>
    {children || Object.keys(schema.properties).map((name) => <FormControl path={name} />)}
  </FormContext.Provider>
);
