import { Paragraph } from '@common/Typography/Paragraph';
import { CardContent, styled } from '@mui/material';
import { makeGrid } from '@utils/grid';
import { Range, WithStyle } from '@utils/types';

const sizes = makeGrid(12);

export type ListItemLineProps = WithStyle<{
  heading?: string;
  value?: string;
  children?: React.ReactNode;
  size?: Range<1, 13>;
  pushRight?: boolean;
}>;

export const ListItemLine: React.FC<ListItemLineProps> = ({
  heading,
  value,
  children,
  size = 12,
  pushRight = false,
  className,
}) => (
  <ListItemLineStyled
    className={className}
    width={sizes[size ?? 12]}
    pushRight={pushRight}>
    {Boolean(heading) && <Paragraph color="text.secondary">{heading}</Paragraph>}
    {Boolean(value) && (
      <Paragraph
        size={3}
        weight={500}>
        {value}
      </Paragraph>
    )}
    {children}
  </ListItemLineStyled>
);

const ListItemLineStyled = styled(CardContent, {
  shouldForwardProp: (propName) => propName !== 'width',
})<{ width: string; pushRight: boolean }>`
  width: ${(props) => props.width || '100%'};

  margin-left: ${(props) => (props.pushRight ? 'auto' : 'unset')};
`;
