import { Layout } from '@common/Layout';
import { ProtectedRoute } from '@common/ProtectedRoute';
import { RouteGroup } from '@services/routes/type';
import { memo, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router';

type RendererProps = {
  routes: RouteGroup[];
  default: JSX.Element;
};

const RendererComponent = ({ routes, default: defaultRoute }: RendererProps) => {
  useEffect(() => {
    console.log('ROUTER MOUNTED');
  }, []);

  return (
    <Routes>
      {routes.map(({ default: defaultPath, path: groupPath, routes: items, component: Component = Layout }) => (
        <Route
          key={groupPath}
          path={groupPath}
          element={<Component />}>
          <Route
            path=""
            key="DEFAULT"
            element={
              <Navigate
                replace
                to={`${groupPath}${defaultPath}`}
              />
            }
          />
          {items.map(({ path, permission, component: Component, common = [], routes = [], default: defaultPath }) => (
            <Route
              path={path}
              key={`${groupPath}${path}`}
              element={
                <ProtectedRoute
                  permission={permission}
                  element={<Component />}
                />
              }>
              <Route
                path=""
                key="DEFAULT"
                element={
                  <Navigate
                    replace
                    to={`${defaultPath}`}
                  />
                }
              />
              {routes.map(({ component: SubRouteComponent, ...subRoute }) => (
                <Route
                  path={`${subRoute.path}`}
                  element={
                    <ProtectedRoute
                      permission={subRoute.permission}
                      element={
                        <>
                          <SubRouteComponent />
                          {common.length > 0 && <Outlet />}
                        </>
                      }
                    />
                  }
                  key={subRoute.path}>
                  {common.map(({ component: Component, path, permission }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <ProtectedRoute
                          permission={permission}
                          element={<Component />}
                        />
                      }
                    />
                  ))}
                </Route>
              ))}
            </Route>
          ))}
        </Route>
      ))}
      <Route
        path="*"
        element={defaultRoute}
      />
    </Routes>
  );
};

export const Renderer = memo(RendererComponent);
