import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';

type NavGroupProps = {
  title?: string;
  icon?: ReactNode;
  children: ReactNode;
};

const NavGroupStyled = styled('li')`
  padding: 0.25rem 1rem;
  font-size: 0.9rem;
`;

//const NavGroupTitle = styled(Box)`
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//
//  gap: 0.5rem;
//
//  font-size: 1.4em;
//  color: ${({ theme }) => theme.palette.grey[700]};
//  padding: 0.25rem 1rem 0.75rem;
//`;

export const NavGroup = ({ children, title, icon }: NavGroupProps) => (
  <NavGroupStyled>
    {/*title && icon ? (
      <NavGroupTitle>
        <Box>{icon}</Box>
        <Box>{title}</Box>
      </NavGroupTitle>
    ) : null*/}
    <Box>{children}</Box>
  </NavGroupStyled>
);
