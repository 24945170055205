import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import { deleteUser } from '@services/api/users';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type UseRemoveUser = {
  id?: string;
  onSuccess: () => void;
};

export const useRemoveUser = ({ id, onSuccess }: UseRemoveUser) => {
  const { t } = useTranslation();
  const { call, isLoading } = useApi({
    initialLoading: false,
    method: deleteUser,
    onSuccess: useCallback<UseApiSuccessEvent<void>>(
      ({ onSuccessNotification }) => {
        onSuccessNotification(t('User has been removed'));
        onSuccess();
      },
      [t, onSuccess],
    ),
  });

  return {
    onRemove: useCallback(() => {
      if (id) {
        call(id);
      }
    }, [call, id]),
    isLoading,
  };
};
