import { selectUserPermission } from '@store/user/selectors';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

export enum UserPermission {
  NOT_ALLOWED = 0,
  READ = 1,
  WRITE = 3,
  ADMIN = 7,
  GUEST = 2,
}

export type UserType = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  permission: UserPermission;
};

export const getPermissionName = (permission: UserPermission): string => {
  switch (permission) {
    case UserPermission.ADMIN:
      return t('Admin');
    case UserPermission.GUEST:
      return t('Guest');
    case UserPermission.READ:
      return t('Read');
    case UserPermission.WRITE:
      return t('Write');
  }

  return t('Permission unknown');
};

export const useLimitTo = (permission: UserPermission) => {
  const actual = useSelector(selectUserPermission);

  return permission === UserPermission.GUEST || (permission & actual) === permission;
};
