import { UserPermission } from '@services/model/user';
import { RouteGroup } from '@services/routes/type';

import { Dashboard } from './index';

export const routes: RouteGroup = {
  path: '/',
  routes: [
    {
      path: 'dashboard',
      permission: UserPermission.READ,
      component: Dashboard,
    },
  ],
};
