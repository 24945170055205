import { UserPermission } from '@services/model/user';
import { isAllowed } from '@services/model/user/checks';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteProps } from 'react-router-dom';
import { selectUserPermission } from 'src/store/user/selectors';

type ProtectedRouteProps = RouteProps & {
  permission: UserPermission;
  element: React.ReactNode;
};

const ProtectedRouteComponent = ({ permission, element }: ProtectedRouteProps) => {
  const actual = useSelector(selectUserPermission);

  if (isAllowed(permission, actual)) {
    return <>{element}</>;
  }

  return <Navigate to="/login" />;
};

export const ProtectedRoute = memo(ProtectedRouteComponent);
