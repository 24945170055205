import { ToDoItem } from '@screens/Clients/ClientEditPage/ToDoList/types';
import { Client, ClientAddress, ClientContactData } from '@services/model/client/Client';
import { Meeting } from '@services/model/client/Meeting';
import { orderBy } from '@utils/fp';
import { AxiosResponse } from 'axios';

import { ApiType, pick, StructuredResponse, unpack } from './utils';

type ClientBody = ClientContactData & ClientAddress;

type ClientResponse = StructuredResponse<
  Omit<Client, 'meetings'> & {
    meetings: Record<string, Meeting>;
    actions: Record<string, any>;
    buildingActions: Record<string, any>;
  }
>;

const unpackClient =
  () =>
  (response: AxiosResponse<ClientResponse>): Client => {
    const { meetings, actions, buildingActions, ...data } = pick(response.data);

    return {
      ...data,
      meetings: orderBy(Object.values(meetings ?? {}), 'orderNumber'),
      actions: orderBy(Object.values(actions ?? {}), 'orderNumber'),
      buildingActions: orderBy(Object.values(buildingActions ?? {}), 'orderNumber'),
    };
  };

export const listClients = (api: ApiType) => async () =>
  await api.get<StructuredResponse<Client[]>>('client').then(unpack());

export const createClient = (api: ApiType) => async (body: ClientBody) =>
  await api.post<ClientResponse>('client', body).then(unpackClient());

export const getClient = (api: ApiType) => async (id: string) =>
  await api.get<ClientResponse>(`client/${id}`).then(unpackClient());

export const updateClient = (api: ApiType) => async (id: string, body: Partial<Client>) =>
  await api.put<ClientResponse>(`client/${id}`, body).then(unpackClient());

export const updateBuilding = (api: ApiType) => async (id: string, body: Partial<Client['building']>) =>
  await api.put<ClientResponse>(`client/${id}/building`, body).then(unpackClient());

export const updateInterview = (api: ApiType) => async (id: string, body: Partial<Client['interview']>) =>
  await api
    .put<ClientResponse>(`client/${id}/interview`, {
      interview: body,
    })
    .then(unpackClient());

export const updateClientIdentity = (api: ApiType) => async (id: string, body: Partial<Client['identity']>) =>
  await api
    .put<ClientResponse>(`client/${id}/identity`, {
      identity: body,
    })
    .then(unpackClient());

export const createClientToDo = (api: ApiType) => async (type: string, id: string, item: Record<string, string>) =>
  api.post<StructuredResponse<ToDoItem>>(`client/${id}/${type}`, item).then(unpack());

export const updateClientToDo =
  (api: ApiType) => async (type: string, id: string, item: Partial<Record<string, any>>) =>
    await api.put<StructuredResponse<ToDoItem>>(`client/${id}/${type}/${item.id}`, item).then(unpack());

export const removeClientToDo = (api: ApiType) => async (id: string, type: string, itemId: string) =>
  api.delete<void>(`client/${id}/${type}/${itemId}`);

export const getClientToDo = (api: ApiType) => async (type: string, id: string, itemId: string) =>
  api.get<StructuredResponse<ToDoItem>>(`client/${id}/${type}/${itemId}`).then(unpack());

export const deleteClient = (api: ApiType) => async (id: string) => await api.delete<void>(`client/${id}`);
