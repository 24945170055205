import { first, uppercase } from '@utils/fp';

const normalize = (hash: number, min: number, max: number) => Math.floor((hash % (max - min)) + min);

export const textToHexColor = (text: string): string => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  hash = Math.abs(hash);

  return `hsl(${normalize(hash, 0, 360)}, ${normalize(hash, 0, 100)}%, ${normalize(hash, 0, 100)}%)`;
};

export const uf = (s: string) => uppercase(first(s));
