import { WithTranslation } from '@utils/types';
import * as yup from 'yup';

export const AuthorizationSchema = ({ t }: WithTranslation) =>
  yup.object({
    email: yup.string().email(t('Invalid e-mail address')).required(t(`E-mail address is required`)),
    password: yup.string().required(t(`Password is required`)),
  });

export type AuthorizationSchemaType = yup.InferType<ReturnType<typeof AuthorizationSchema>>;
