import { BucketDescription, BucketType } from '@services/model/bucket/Bucket';
import { orderBy } from '@utils/fp';

import { ApiType, nope, unpack } from './utils';

export const listBucket = (api: ApiType) => async () => {
  const response = await api.get(`/bucket/default`).then(unpack<BucketType[]>());

  return orderBy(response, 'name');
};

export const uploadToBucket = (api: ApiType) => async (bucket: string, body: File) =>
  api
    .post(`/bucket/${bucket}`, body, {
      headers: {
        'X-FileName': body.name.substring(0, body.name.lastIndexOf('.')),
        'X-FileExt': body.name.substring(body.name.lastIndexOf('.')),
      },
    })
    .then(unpack<BucketType>());

export const patchBucket = (api: ApiType) => async (bucket: string, id: string, body: BucketDescription) =>
  api.patch(`/bucket/${bucket}/${id}`, body).then(unpack<BucketType>());

export const getFromBucket = (api: ApiType) => async (bucket: string, id: string) =>
  api.get(`/bucket/${bucket}/${id}`).then(unpack<BucketType>());

export const deleteBucketFile = (api: ApiType) => async (bucket: string, id: string) =>
  api.delete<void>(`/bucket/${bucket}/${id}`).then(nope());
