import { FormFieldType } from '@common/Form/types';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { wordize } from '@utils/fp';
import { WithForwardRef } from '@utils/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TitleFieldProps = WithForwardRef<FormFieldType<string>, HTMLInputElement>;

type TitleFieldOption = {
  value: string;
  title: string;

  inputValue?: string;
};

const filter = createFilterOptions<TitleFieldOption>();

const options: Record<string, TitleFieldOption[]> = {
  meetings: [
    { title: '', value: 'informational_meeting' },
    { title: '', value: 'technical_meeting' },
    { title: '', value: 'phone_contact' },
    { title: '', value: 'contractor_meeting' },
    { title: '', value: 'materials_handover' },
    { title: '', value: 'document_preparation' },
    { title: '', value: 'other_meeting' },
  ],
  buildingActions: [
    { title: '', value: 'energyAudit' },
    { title: '', value: 'heatingSystemReplacement' },
    { title: '', value: 'RenewableEnergySourceInstallation' },
    { title: '', value: 'wallInsulation' },
    { title: '', value: 'doorsWindowsChange' },
    { title: '', value: 'roofInsulation' },
    { title: '', value: 'drainage' },
    { title: '', value: 'other' },
  ],
  actions: [
    { title: '', value: 'PhoneCall' },
    { title: '', value: 'DirectContact' },
    { title: '', value: 'MaterialsHandover' },
    { title: '', value: 'ExpertMeeting' },
    { title: '', value: 'SecureFinancing' },
    { title: '', value: 'ThermalModernization' },
    { title: '', value: 'Other' },
  ],
};

export const TitleField: React.FC<TitleFieldProps> = ({ value, onChange, name, disabled, ...rest }) => {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation();

  const type = watch('group');

  const [actions, nextValue] = useMemo(() => {
    const actions = (options[type] || []).map((option) => ({
      ...option,
      title: t(wordize(option.value)),
    }));

    let action = actions.find((action) => action.value === value);

    if (!action && value) {
      action = {
        title: t(value as string),
        value,
      };

      actions.push(action);
    }

    return [actions, action];
  }, [type, t, value]);

  return (
    <Autocomplete
      value={nextValue}
      disabled={disabled}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue(name, newValue, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        } else if (newValue && newValue.inputValue) {
          setValue(name, newValue.inputValue, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        } else if (newValue && newValue.value) {
          setValue(name, newValue.value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        } else {
          setValue(name, '', {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
      }}
      selectOnFocus
      clearOnBlur
      freeSolo
      options={actions}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting = options.some((option) => option.title.includes(inputValue));

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
            value: inputValue,
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        if (option.inputValue) {
          return option.inputValue;
        }

        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      renderInput={(params) => (
        <TextField
          name={name}
          disabled={disabled}
          {...params}
        />
      )}
    />
  );
};
