import { UserPermission } from '@services/model/user';
import { RouteGroup } from '@services/routes/type';

import { BucketListPage } from './BucketListPage/BucketListPage';

export const routes: RouteGroup = {
  path: '/bucket',
  default: 'browse',
  routes: [
    {
      path: 'browse',
      permission: UserPermission.READ,
      component: BucketListPage,
    },
  ],
};
