import { PropertyTypes, SchemaObject, SchemaProperty } from './useJsonSchema';

type AnySchema = SchemaObject | SchemaProperty;

export const resolveSchema =
  (schema: AnySchema | undefined) =>
  (path: string): AnySchema | undefined => {
    if (schema?.type === PropertyTypes.Object && path.length > 0) {
      const slugs = path.split('.');

      return resolveSchema(schema?.properties[slugs[0]])(slugs.slice(1).join('.'));
    }

    return schema;
  };

export const isRequired =
  (schema: AnySchema | undefined) =>
  (path: string): boolean => {
    const slugs = path.split('.');

    const ref = resolveSchema(schema)(slugs.slice(0, -1).join('.'));

    return ref?.type === PropertyTypes.Object && ref?.required?.includes(slugs[slugs.length - 1]);
  };
