import { AppLogo } from '@common/Assets/AppLogo';
import { CompaniesFooter } from '@common/Assets/CompaniesFooter';
import { LoginBackground } from '@common/Assets/LoginBackground';
import { PlanergiaLogo } from '@common/Assets/PlanergiaLogo';
import { Heading } from '@common/Typography/Heading';
import { Paragraph } from '@common/Typography/Paragraph';
import { Box, styled } from '@mui/material';
import { UserPermission } from '@services/model/user';
import { isAllowed } from '@services/model/user/checks';
import { selectUserPermission } from '@store/user/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { SignInForm } from './Form/SignInForm';

type SignInPageComponentProps = {
  className?: string;
};

const SignInPageComponent = ({ className }: SignInPageComponentProps) => {
  const { t } = useTranslation();
  const permission = useSelector(selectUserPermission);

  if (isAllowed(UserPermission.READ, permission)) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Container className={className}>
      <Box
        hidden
        position="absolute"
        top="1rem"
        left="1rem">
        <PlanergiaLogo
          variant="filled"
          size={64}
        />
      </Box>
      <SignInBox>
        <Heading size={4}>{t('Sign in')}</Heading>
        <Paragraph space>{t('Please sign in to continue')}</Paragraph>
        <SignInForm />
      </SignInBox>
      <Footer />
    </Container>
  );
};

const SignInBox = styled(Box)`
  max-width: 32rem;
  width: 100%;
  padding: 2rem 2.75rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 1px 2px 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const Footer = styled(CompaniesFooter)`
  z-index: 1;
  padding: 3em 0 0em;
  width: 90%;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 70%;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 60%;
  }

  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 50%;
  }
`;

const Container = styled(Box)`
  backdrop-filter: blur(1px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SignInPage = styled(SignInPageComponent)`
  min-height: 100vh;
  background: #f8ccad;

  &::before {
    z-index: 0;
    background-color: ${(props) => props.theme.palette.common.black};
    ${AppLogo};
  }
`;
