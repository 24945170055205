import { UserType } from '@services/model/user';
import { UserPermission } from 'src/services/model/user';

export type UserStore = {
  user: UserType;
};

export const initialState: UserStore = {
  user: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    permission: UserPermission.NOT_ALLOWED,
  },
};

//export const initialState: UserType = {
//  id: '2137',
//  firstname: 'Karol',
//  lastname: 'Wojtyła',
//  email: 'karol.wojtyła@watykan.com',
//  permission: UserPermission.NOT_ALLOWED,
//  //UserPermission.NOT_ALLOWED,
//};
