import { Button } from '@common/Button/Button';
import { UncontrolledDrawer } from '@common/Drawer/UncontrolledDrawer';
import { Paragraph } from '@common/Typography/Paragraph';
import { useTranslation } from 'react-i18next';

import { AddUserModalForm } from './AddUserModalForm';

type AddUserModalProps = {
  onSuccess: () => void;
};

export const AddUserModal: React.FC<AddUserModalProps> = ({ onSuccess }) => {
  const [t] = useTranslation();

  const title = t('Add user');

  return (
    <UncontrolledDrawer
      title={title}
      cancelable
      handler={({ onOpen }) => (
        <Button
          onClick={onOpen}
          color="primary">
          {title}
        </Button>
      )}>
      {({ onClose }) => (
        <>
          <Paragraph space>
            {t('Here you can create a new system user which will be responsible for managing Clients')}
          </Paragraph>
          <AddUserModalForm
            onSuccess={() => {
              onSuccess();
              onClose();
            }}
          />
        </>
      )}
    </UncontrolledDrawer>
  );
};
