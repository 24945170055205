import { Mime } from '@common/File/Mime';
import { ContentBox } from '@common/Layout/Content/ContentBox';
import { ContentHeader } from '@common/Layout/Content/ContentHeader';
import { ContentNavbar } from '@common/Layout/Content/ContentNavbar';
import { SearchInput } from '@common/List/Actions/Search/SearchInput';
import { Table } from '@common/Table/Table';
import { Paragraph } from '@common/Typography/Paragraph';
import { useApi } from '@hooks/useApi';
import { useList } from '@hooks/useList';
import { listBucket } from '@services/api/buckets';
import { BucketType } from '@services/model/bucket/Bucket';
import { searchAll } from '@utils/fp';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadLink } from './DownloadLink';
import { FileUploadModal } from './FileUploadModal/FileUploadModal';
import { RemoveFileItem } from './RemoveFileItem';

const headers = {
  name: {
    label: t('File name'),
    value: capitalize,
  },
  mime: {
    label: t('File type'),
    value: (value: string) => <Mime value={value} />,
  },
  description: {
    label: t('File description'),
  },
};

export const BucketListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const {
    data: body = [],
    call,
    isLoading,
  } = useApi({
    method: listBucket,
    initialLoading: true,
  });

  const { data, visible, rootRef, onSearchChange } = useList<BucketType, HTMLDivElement>({
    data: body,
    match: searchAll,
  });

  useEffect(() => {
    call();
  }, [call]);

  return (
    <>
      <ContentHeader
        subtitle={t('List of guidance materials')}
        title={t('Browse materials')}
        actions={
          <>
            <FileUploadModal onSuccess={call} />
          </>
        }
      />
      <ContentNavbar>
        <SearchInput
          placeholder={t('Type to search')}
          onChange={onSearchChange}
        />
        <Paragraph space={0}>
          {t('Total {{visible}} of {{total}}', {
            total: body.length,
            visible,
          })}
        </Paragraph>
      </ContentNavbar>
      <ContentBox
        table
        loading={isLoading}>
        <Table
          forwardRef={rootRef}
          headers={headers}
          data={data}
          leadingActions={({ item }) => <DownloadLink id={item.id} />}
          actions={({ item }) => (
            <>
              <RemoveFileItem
                id={item.id}
                onSuccess={() => call()}
              />
            </>
          )}
        />
      </ContentBox>
    </>
  );
};
