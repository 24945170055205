import BuildingSchema from '@assets/schema/client/building.json';
import { Card } from '@common/Card/Card';
import { CardsLayout } from '@common/Card/CardsLayout';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { TextArea } from '@common/Form/TextArea';
import { useApi } from '@hooks/useApi';
import Grid from '@mui/material/Grid';
import { updateBuilding } from '@services/api/client';
import { Client } from '@services/model/client/Client';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormHeader } from './FormHeader';
import { useClientContext } from './useClientTabsContext';

const limit = (client: Client) => client.building;

type SectionProps = {
  isLoading: boolean;
  submit: () => void;
};

const BuildingGeneral: React.FC<SectionProps> = ({ submit, isLoading }) => {
  const { t } = useTranslation();

  return (
    <form onBlur={submit}>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}>
          <FormHeader
            title={t('General information')}
            loading={isLoading}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl path="type" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl path="ownership" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl
            path="area"
            endAdornment={
              <div>
                m<sup>2</sup>
              </div>
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl path="roomsNumber" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl path="buildYear" />
        </Grid>
      </Grid>
    </form>
  );
};

const BuildingHeating = ({ submit, isLoading }: SectionProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const onChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const { target } = e;

    if (target?.getAttribute('type') === 'checkbox') {
      submit(e);
    }
  };

  return (
    <form
      onBlur={submit}
      onChange={onChange}>
      <Grid
        container
        spacing={2}>
        <Grid
          item
          xs={12}>
          <FormHeader
            title={t('Heating & Insulation')}
            loading={isLoading}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl
            path="waterHeatingCost"
            endAdornment={<div>zł</div>}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl
            path="electricityCost"
            endAdornment={<div>zł</div>}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl path="heatingSource" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}>
          <FormControl path="antiSmogAct" />
          {watch('antiSmogAct') && <FormControl path="heatingSourceChangeYear" />}
          <FormControl path="photovoltaic" />
          <FormControl path="insulation" />
        </Grid>
      </Grid>
    </form>
  );
};

const BuildingCondition = ({ submit, isLoading }: SectionProps) => {
  const { t } = useTranslation();

  return (
    <form onBlur={submit}>
      <Grid
        spacing={2}
        container>
        <Grid
          item
          md={12}>
          <FormHeader
            title={t('Building condition')}
            loading={isLoading}
          />
        </Grid>
        <Grid
          item
          md={12}>
          <FormControl path="condition" />
        </Grid>
        <Grid
          item
          md={12}>
          <FormControl
            path="description"
            control={TextArea}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export const BuildingTab = () => {
  const { client, patch } = useClientContext();

  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: BuildingSchema as unknown as SchemaObject,
    mode: 'onBlur',
    defaultValues: limit(client),
  });

  const { isLoading, call } = useApi({
    method: updateBuilding,
    initialLoading: false,
    onSuccess: useCallback(
      ({ data }: { data: Client }) => {
        patch(() => data);
      },
      [patch],
    ),
    onError: onErrorEvent,
  });

  const onSubmit = (value: Client['building']) => {
    if (!isEqual(client.building, value)) {
      call(client.id, {
        building: value,
      });
    }
  };

  return (
    <FormProvider {...controls}>
      <Form schema={schema}>
        <CardsLayout>
          <Card full>
            <BuildingGeneral
              isLoading={isLoading}
              submit={controls.handleSubmit(onSubmit)}
            />
          </Card>
          <Card full>
            <BuildingHeating
              isLoading={isLoading}
              submit={controls.handleSubmit(onSubmit)}
            />
          </Card>
          <Card full>
            <BuildingCondition
              isLoading={isLoading}
              submit={controls.handleSubmit(onSubmit)}
            />
          </Card>
        </CardsLayout>
      </Form>
    </FormProvider>
  );
};
