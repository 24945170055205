import { yupResolver } from '@hookform/resolvers/yup';
import { UseApiErrorEvent } from '@hooks/useApi';
import { applyFirstSchemaError, isSchemaError, SchemaErrorResponse } from '@services/api/utils';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type EnumValue = string | number;

export enum PropertyTypes {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Object = 'object',
  Array = 'array',
}

export enum PropertyFormat {
  Email = 'email',
  Password = 'password',
  Year = 'year',
}

export type SchemaProperty = {
  title?: string;
  description?: string;
  type: PropertyTypes.String | PropertyTypes.Number | PropertyTypes.Boolean;
  format?: 'email' | 'password' | 'textarea' | 'year';
  enum?: EnumValue[];
  enumNames?: string[];
  default?: string | number;
  minimum?: number;
  maximum?: number;
};

export type SchemaObjectProperties = {
  [key: string]: SchemaProperty;
};

export type SchemaObjectRequiredProperties<T extends SchemaObjectProperties> = (keyof T)[];

export type SchemaObject<
  P extends SchemaObjectProperties = SchemaObjectProperties,
  R extends SchemaObjectRequiredProperties<P> = SchemaObjectRequiredProperties<P>,
> = Omit<SchemaProperty, 'type' | 'format' | 'enum' | 'enumNames'> & {
  type: PropertyTypes.Object;
  required: R;
  properties: P;
};

export type Schema<T> = T extends SchemaObject ? SchemaObject : T extends SchemaArray ? SchemaArray : SchemaProperty;

export type AnySchema = SchemaObject | SchemaProperty | SchemaArray<SchemaProperty>;

export type SchemaArray<T extends SchemaProperty = SchemaProperty> = Omit<
  SchemaProperty,
  'type' | 'format' | 'enum' | 'enumNames'
> & {
  type: PropertyTypes.Array;
  items: Schema<T>;
};

type YupStrings = ReturnType<typeof yup.string>;
type YupObjects = ReturnType<typeof yup.object>;
type YupNumbers = ReturnType<typeof yup.number>;
type YupBoolean = ReturnType<typeof yup.bool>;
type YupArray = ReturnType<typeof yup.array>;
type YupSchemas = YupNumbers | YupStrings | YupObjects | YupBoolean | YupArray;

const required = (isRequired: boolean) => (y: YupSchemas) => {
  if (isRequired) {
    return y.required(t('Field is required'));
  }

  return y;
};

const format = (property: SchemaProperty) => (y: YupSchemas) => {
  if (property.type === PropertyTypes.String) {
    if (property.format === PropertyFormat.Email) {
      return (y as YupStrings).email(t('Invalid e-mail address'));
    }

    if (property.format === PropertyFormat.Year) {
      return (y as YupStrings).matches(/^(1[2-9][0-9][0-9])|(20[0-9]{2,2})$/, {
        message: t('Invalid year format'),
        excludeEmptyString: true,
      });
    }
  }

  return y;
};

const enums = (property: SchemaProperty) => (y: YupSchemas) => {
  if (!property.enum) {
    return y;
  }

  return y.test(property, t('Value is not one of given'), (value) => {
    if (property.enum?.includes(value)) {
      return true;
    }

    if (value === null || value === '' || value === 0) {
      return true;
    }

    return false;
  });
};

const minmax = (property: SchemaProperty) => (y: YupSchemas) => {
  if (typeof property.minimum !== 'undefined') {
    return (y as YupNumbers).min(
      property.minimum,
      t('Minimum allowed value is {{value}}', {
        value: property.minimum,
      }),
    );
  }

  if (typeof property.maximum !== 'undefined') {
    return (y as YupNumbers).max(
      property.maximum,
      t('Maximum allowed value is {{value}}', {
        value: property.maximum,
      }),
    );
  }

  return y;
};

const compose = (fn: ((y: YupSchemas) => YupSchemas)[]) => (y: YupSchemas) => fn.reduce((carry, fn) => fn(carry), y);

const pipe = (property: SchemaProperty, isRequired: boolean) =>
  compose([format(property), enums(property), required(isRequired), minmax(property)]);

const create = <T>(property: Schema<T>, isRequired: boolean): YupSchemas => {
  if (property.type === PropertyTypes.Object) {
    return obj(property);
  }

  if (property.type === PropertyTypes.Array) {
    return array(property);
  }

  const pipeline = pipe(property, isRequired);

  if (property.type === PropertyTypes.String) {
    return pipeline(yup.string());
  }

  if (property.type === PropertyTypes.Number) {
    return pipeline(yup.number().typeError(t('You must specify a number')));
  }

  if (property.type === PropertyTypes.Boolean) {
    return pipeline(yup.bool().typeError(t('Value must be a yes or no')));
  }

  throw new Error('Unknown property type');
};

const array = (property: SchemaArray) => yup.array(create(property.items, false));

const obj = (property: SchemaObject) => {
  const props = (property as Schema<SchemaObject>).properties;

  return yup.object(
    Object.entries(props).reduce(
      (carry, [name, prop]) => ({
        ...carry,
        [name]: create(prop, (property as Schema<SchemaObject>)?.required?.includes(name)),
      }),
      {} as {
        [key: string]: YupSchemas;
      },
    ),
  );
};

type UseJsonSchemaProps = {
  schema: SchemaObject;
  defaultValues?: yup.InferType<ReturnType<typeof obj>>;
  mode?: 'onChange' | 'onBlur';
  include?: string[];
};

const limit = (schema: SchemaObject, properties: string[]): SchemaObject => {
  if (!properties.length) {
    return schema;
  }

  const defaultSchema: SchemaObject = {
    type: PropertyTypes.Object,
    properties: {},
    required: [],
  };

  return properties.reduce((carry, property) => {
    if (property in schema.properties) {
      carry.properties[property] = { ...schema.properties[property] };

      if (schema.required?.includes(property)) {
        carry.required.push(property);
      }
    }

    return carry;
  }, defaultSchema);
};

export const useJsonSchema = ({ schema, defaultValues, mode = 'onChange', include = [] }: UseJsonSchemaProps) => {
  const validator = useMemo(() => obj(limit(schema, include)), [schema, include]);

  const controls = useForm({
    mode,
    defaultValues,
    resolver: yupResolver(validator),
  });

  const { t } = useTranslation();
  const { setError } = controls;

  return {
    validator,
    controls,
    schema: limit(schema, include),
    onErrorEvent: useCallback<UseApiErrorEvent>(
      ({ error, onErrorNotification }) => {
        onErrorNotification(t(error?.message ?? 'Error occurred during request processing'));

        if (error && isSchemaError(error)) {
          applyFirstSchemaError(error as SchemaErrorResponse, (error) => {
            setError(
              error.field,
              {
                type: 'value',
                message: t(error.message),
              },
              {
                shouldFocus: true,
              },
            );
          });
        }
      },
      [t, setError],
    ),
  };
};
