import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router';

export const Content = () => (
  <ContentStyled>
    <Outlet />
  </ContentStyled>
);

const ContentStyled = styled('div')`
  grid-area: content;

  display: grid;
  grid-template: 'header' 'navbar' 'content' 1fr;

  height: 100vh;
  width: 100%;
`;
