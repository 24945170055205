import { FirebaseOptions } from 'firebase/app';

export const firebase = {
  options: JSON.parse(process.env.REACT_APP_FIREBASE) as FirebaseOptions,
  authEmulator: process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST,
};

export const api = {
  apiUrlPrefix: process.env.REACT_APP_API_URL_PREFIX || '/api',
  apiUrlHost: process.env.REACT_APP_HOST || '',
};
