import { ColorVariants, WithStyled } from '@common/types';
import { Typography } from '@mui/material';
import { WithChildren } from '@utils/types';

type HeadingSizes = 1 | 2 | 3 | 4 | 5 | 6;

type HeadingProps = WithStyled<
  WithChildren<{
    gutterBottom?: boolean;
    center?: boolean;
    color?: ColorVariants;
    size: HeadingSizes;
  }>
>;

export const Heading: React.FC<HeadingProps> = ({ children, size, color, center, className, gutterBottom = true }) => (
  <Typography
    className={className}
    gutterBottom={gutterBottom}
    color={color}
    textAlign={center ? 'center' : undefined}
    variant={`h${size}`}>
    {children}
  </Typography>
);
