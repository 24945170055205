import CreateUserSchema from '@assets/schema/user/create.json';
import { Button } from '@common/Button/Button';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { useApi } from '@hooks/useApi';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CommuneField } from '@screens/Clients/common/CommuneField';
import { VoivodeshipField } from '@screens/Clients/common/VoivodeshipField';
import { createUser } from '@services/api/users';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AddUserModalFormProps = {
  onSuccess: () => void;
};

export const AddUserModalForm: React.FC<AddUserModalFormProps> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: CreateUserSchema as unknown as SchemaObject,
  });

  const { call, isLoading } = useApi({
    method: createUser,
    onSuccess: ({ onSuccessNotification }) => {
      onSuccessNotification(t(`User has been created`));
      onSuccess();
    },
    onError: onErrorEvent,
  });

  return (
    <FormProvider {...controls}>
      <form
        onSubmit={controls.handleSubmit(call)}
        noValidate>
        <Form schema={schema}>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              xs={12}>
              <FormControl path="email" />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl path="password" />
            </Grid>
            <Grid
              item
              md={6}>
              <FormControl path="firstname" />
            </Grid>
            <Grid
              item
              md={6}>
              <FormControl path="lastname" />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="county"
                control={VoivodeshipField}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl
                path="commune"
                control={CommuneField}
              />
            </Grid>
            <Grid
              item
              xs={12}>
              <FormControl path="permission" />
            </Grid>
          </Grid>
        </Form>
        <Box mt={5}>
          <Button
            fluent
            loading={isLoading}
            type="submit"
            disabled={!controls.formState.isValid}
            color="primary">
            {t('Save')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
