import { IconButton } from '@common/Button/IconButton';
import { Spinner } from '@common/Spinner/Spinner';
import { Heading } from '@common/Typography/Heading';
import { Box, Modal as ModalBase, styled } from '@mui/material';
import { FiX } from 'react-icons/fi';

type ModalProps = {
  open: boolean;
  title: string;
  children: React.ReactNode;
  cancelable?: boolean;
  loading?: boolean;
  onClose: () => void;
};

export const Modal: React.FC<ModalProps> = ({
  children,
  title,
  onClose,
  open,
  cancelable = false,
  loading = false,
}) => (
  <ModalBase {...{ onClose, open }}>
    <StyledWrapper>
      <StyledContent>
        <StyledHeader>
          <Heading
            size={5}
            gutterBottom={false}>
            {title}
          </Heading>
          {cancelable && (
            <StyledCloseButton
              color="contained"
              onClick={onClose}
              icon={<FiX />}
            />
          )}
        </StyledHeader>
        <StyledBody>{loading ? <StyledSpinner /> : children}</StyledBody>
      </StyledContent>
    </StyledWrapper>
  </ModalBase>
);

const StyledSpinner = styled(Spinner)`
  margin: 2rem auto;
`;

const StyledWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 2rem;
`;

const StyledCloseButton = styled(IconButton)`
  margin-right: -1rem;
`;

const StyledContent = styled(Box)`
  max-width: 40rem;
  width: 100%;
  height: min-content;

  background: ${(props) => props.theme.palette.background.paper};
  box-shadow: ${(props) => props.theme.shadows[5]};
  z-index: 1400;
`;

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

const StyledBody = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
`;
