import { DropdownItem } from '@common/Dropdown/Item';
import { Icon } from '@common/Icon/Icon';
import { useTranslation } from 'react-i18next';

import { useRemoveClient } from './useRemoveClient';

type RemoveClientItemProps = {
  id: string;
  onSuccess: () => void;
};

export const RemoveClientItem: React.FC<RemoveClientItemProps> = ({ id, onSuccess }) => {
  const { t } = useTranslation();
  const { onRemove } = useRemoveClient({
    id,
    onSuccess,
  });

  return (
    <DropdownItem
      onClose={onRemove}
      text={t('Remove client')}
      icon={<Icon name="remove" />}
    />
  );
};
