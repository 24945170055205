import CreateClientSchema from '@assets/schema/client/create.json';
import { Card } from '@common/Card/Card';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { useApi } from '@hooks/useApi';
import Grid from '@mui/material/Grid';
import { CommuneField } from '@screens/Clients/common/CommuneField';
import { VoivodeshipField } from '@screens/Clients/common/VoivodeshipField';
import { updateClient } from '@services/api/client';
import { Client } from '@services/model/client/Client';
import { isEqual, pick } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormHeader } from './FormHeader';
import { useClientContext } from './useClientTabsContext';

export const limit = (client: Client) =>
  pick(client, ['firstName', 'lastName', 'commune', 'county', 'street', 'house']);

type ClientOverview = Partial<ReturnType<typeof limit>>;

export const ClientOverviewTab = () => {
  const { client, patch } = useClientContext();
  const { t } = useTranslation();

  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: CreateClientSchema as unknown as SchemaObject,
    mode: 'onBlur',
    defaultValues: limit(client),
  });

  const { reset } = controls;

  useEffect(() => {
    if (client) {
      reset(limit(client));
    }
  }, [client, reset]);

  const { isLoading, call } = useApi({
    method: updateClient,
    initialLoading: false,
    onSuccess: useCallback(
      ({ data }: { data: Client }) => {
        patch(() => data);
      },
      [patch],
    ),
    onError: onErrorEvent,
  });

  const onSubmit = useCallback(
    (values: ClientOverview) => {
      if (!isEqual(limit(client), values)) {
        call(client.id, values);
      }
    },
    [call, client],
  );

  return (
    <FormProvider {...controls}>
      <Card full>
        <form onBlur={controls.handleSubmit(onSubmit)}>
          <Form schema={schema}>
            <Grid
              container
              spacing={2}>
              <Grid
                item
                xs={12}>
                <FormHeader
                  title={t('Contact details')}
                  loading={isLoading}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="firstName" />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="lastName" />
              </Grid>
              <Grid
                item
                xs={12}>
                <FormHeader
                  title={t('Address')}
                  loading={isLoading}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl
                  path="county"
                  control={VoivodeshipField}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl
                  path="commune"
                  control={CommuneField}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="street" />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="house" />
              </Grid>
            </Grid>
          </Form>
        </form>
      </Card>
    </FormProvider>
  );
};
