import { useCallback, useEffect, useState } from 'react';

import { useChunkedList, UseChunkedListProps } from './useChunkedList';

type UseListProps<T> = UseChunkedListProps<T> & {
  match: (query: string, object: T) => boolean;
};

export const useList = <T extends object, E extends HTMLElement>({ data, match, visible = 10 }: UseListProps<T>) => {
  const [matching, setMatching] = useState<T[]>(data);

  const onSearchChange = useCallback(
    (query: string) => {
      if (!query.length) {
        setMatching(data);
      } else {
        setMatching(data.filter((item) => match(query, item)));
      }
    },
    [match, data],
  );

  useEffect(() => {
    setMatching(data);
  }, [data]);

  return {
    ...useChunkedList<E, T>({
      data: matching,
      visible,
    }),
    onSearchChange,
  };
};
