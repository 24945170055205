import { Spinner } from '@common/Spinner/Spinner';
import { ButtonBase as MuiButton, styled, Theme } from '@mui/material';
import { WithForwardRef, WithStyle } from '@utils/types';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';

export type ButtonProps = WithStyle<{
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode | ReactNode[];
  color?: 'primary' | 'secondary' | 'success' | 'contained' | 'error';
  type?: 'submit' | 'button';
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  fluent?: boolean;
}>;

function variant(theme: Theme, color: ButtonProps['color']) {
  if (color === 'contained') {
    return '';
  }

  return `
    color: ${theme.palette[color!].contrastText};
    background-color: ${theme.palette[color!].main}
  `;
}

const ButtonStyled = styled(MuiButton)<Partial<ButtonProps>>`
  display: inline-flex;

  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  width: ${(props) => (props.fluent ? '100%' : 'auto')};
  & {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }

  ${(props) => variant(props.theme, props.color || 'primary')};

  ${(props) => props.disabled && `filter: grayscale(1); opacity: .25`}

  ${({ loading }) =>
    loading &&
    `
    svg {
      
      animation-duration: 3s;
      animation-name: rotate;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  `}
`;

export const ButtonComponent = ({
  children,
  type,
  color,
  loading,
  disabled,
  className,
  title,
  forwardRef,
  ...rest
}: React.HTMLAttributes<HTMLButtonElement> & WithForwardRef<ButtonProps, HTMLButtonElement>) => (
  <ButtonStyled
    {...rest}
    ref={forwardRef}
    title={title}
    className={className}
    loading={loading}
    disabled={loading || disabled}
    type={type}
    color={loading ? 'secondary' : color}>
    {loading ? <Spinner size="1em" /> : children}
  </ButtonStyled>
);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <ButtonComponent
    {...props}
    forwardRef={ref}
  />
));
