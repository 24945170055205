import { UserType } from '@services/model/user';

import { ApiType, nope, StructuredResponse, unpack } from './utils';

export type CreateUserPayload = Omit<UserType, 'id'> & {
  password: string;
};

export type UpdateUserPayload = Omit<UserType, 'id'> & {
  password?: string;
};

export const getCurrentUser = (api: ApiType) => async () =>
  await api.get<StructuredResponse<UserType>>(`users/me`).then(unpack());

export const createUser = (api: ApiType) => async (body: CreateUserPayload) =>
  await api.post<StructuredResponse<UserType>>('users', body).then(unpack());

export const listUsers = (api: ApiType) => async () =>
  await api.get<StructuredResponse<UserType[]>>('users').then(unpack());

export const getUser = (api: ApiType) => async (id: string) =>
  await api.get<StructuredResponse<UserType>>(`users/${id}`).then(unpack());

export const updateUser = (api: ApiType) => async (id: string, body: UpdateUserPayload) =>
  await api.put<StructuredResponse<UserType>>(`users/${id}`, body).then(unpack());

export const deleteUser = (api: ApiType) => async (id: string) => await api.delete<void>(`users/${id}`).then(nope());
