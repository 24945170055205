import { OutlinedInput, styled, TextareaAutosize, Theme } from '@mui/material';
import { WithForwardRef } from '@utils/types';
import { forwardRef } from 'react';

import { FormFieldType } from './types';

type TextAreaStyleProps = {
  disabled: boolean;
  variant: 'normal' | 'contained';
};

type TextAreaProps = WithForwardRef<
  FormFieldType<string, HTMLTextAreaElement> & Partial<TextAreaStyleProps>,
  HTMLTextAreaElement
>;

const TextareaAutosizeBase = (props: Record<string, any>) => (
  <TextareaAutosize
    {...props}
    minRows={5}
  />
);

const TextAreaComponent: React.FC<TextAreaProps> = ({
  forwardRef,
  placeholder,
  value,
  name,
  error,
  onChange,
  onBlur,
  disabled = false,
  variant = 'normal',
}) => (
  <OutlinedInputStyled
    ref={forwardRef}
    name={name}
    value={value ?? ''}
    placeholder={placeholder}
    error={!!error?.message}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
    variant={variant}
    components={{
      Input: TextareaAutosizeBase,
    }}
    minRows={5}
  />
);

const inputVariant = (theme: Theme, variant: TextAreaStyleProps['variant']) => {
  if (variant === 'normal') {
    return '';
  }

  return `
    .MuiInputBase-input {
      padding-top: .5rem;
      padding-bottom: .5rem;
    } 

    fieldset {
      display: none;
    }
  `;
};

const OutlinedInputStyled = styled(OutlinedInput)<TextAreaStyleProps>`
  &&& {
    .MuiInputBase-input {
      border: 0;
      width: 100% !important;
      resize: vertical;

      padding: 1rem 0.75rem;
      font-size: 1rem;
      font-family: ${(props) => props.theme.typography.fontFamily};

      &:focus-visible {
        border: 0;
        outline: none;
      }
    }
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    opacity: 1;
    color: ${theme.palette.grey[500]};
  `}
  ${({ variant, theme }) => inputVariant(theme, variant)};
`;

export const TextArea = forwardRef<HTMLTextAreaElement, Omit<TextAreaProps, 'forwardRef'>>((props, ref) => (
  <TextAreaComponent
    {...props}
    forwardRef={ref}
  />
));
