import { UserPermission } from '@services/model/user';
import { isAllowed } from '@services/model/user/checks';
import { selectUserPermission } from '@store/user/selectors';
import { useSelector } from 'react-redux';

export const usePermission = () => {
  const actual = useSelector(selectUserPermission);

  const permission = (expected: UserPermission) => (actual && isAllowed(expected, actual)) || !actual;

  return {
    permission,
    canWrite: () => permission(UserPermission.WRITE),
    canAdmin: () => permission(UserPermission.ADMIN),
    canRead: () => permission(UserPermission.READ),
  };
};
