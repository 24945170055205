import { UserPermission } from '@services/model/user';
import { RouteGroup } from '@services/routes/type';

import { ClientEditPage } from './ClientEditPage/ClientEditPage';
import { BuildingTab } from './ClientEditPage/Tabs/BuildingTab';
import { ClientOverviewTab } from './ClientEditPage/Tabs/ClientOverviewTab';
import { IdentityTab } from './ClientEditPage/Tabs/IdentityTab';
import { InterviewTab } from './ClientEditPage/Tabs/InterviewTab';
import { ToDoListCreateModal } from './ClientEditPage/ToDoList/CreateModal/ToDoListCreateModal';
import { ToDoListUpdateModal } from './ClientEditPage/ToDoList/UpdateModal/ToDoListUpdateModal';
import { ClientsListPage } from './ClientsListPage/ClientsListPage';

export const routes: RouteGroup = {
  path: '/clients',
  default: '/manage',
  routes: [
    {
      path: 'manage',
      permission: UserPermission.READ,
      component: ClientsListPage,
    },
    {
      path: ':clientId/edit',
      permission: UserPermission.READ,
      component: ClientEditPage,
      default: 'overview',
      common: [
        {
          path: ':type',
          component: ToDoListCreateModal,
          permission: UserPermission.WRITE,
        },
        {
          path: ':type/:id',
          component: ToDoListUpdateModal,
          permission: UserPermission.READ,
        },
      ],
      routes: [
        {
          path: 'overview',
          component: ClientOverviewTab,
          permission: UserPermission.READ,
        },
        {
          path: 'identity',
          component: IdentityTab,
          permission: UserPermission.READ,
        },
        {
          path: 'building',
          component: BuildingTab,
          permission: UserPermission.READ,
        },
        {
          path: 'interview',
          component: InterviewTab,
          permission: UserPermission.READ,
        },
      ],
    },
  ],
};
