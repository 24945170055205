import BucketUpload from '@assets/schema/bucket/upload.json';
import { Button } from '@common/Button/Button';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { TextArea } from '@common/Form/TextArea';
import { UploadInput } from '@common/Form/UploadInput';
import { useApi } from '@hooks/useApi';
import { Box, Grid } from '@mui/material';
import { patchBucket } from '@services/api/buckets';
import { BucketDescription } from '@services/model/bucket/Bucket';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type FileUploadFormProps = {
  onSuccess: () => void;
};

export const FileUploadForm: React.FC<FileUploadFormProps> = ({ onSuccess }) => {
  const { t } = useTranslation();

  const { schema, controls, onErrorEvent } = useJsonSchema({
    schema: BucketUpload as unknown as SchemaObject,
  });

  const { isLoading, call } = useApi({
    method: patchBucket,
    onError: onErrorEvent,
    onSuccess,
  });

  const onSubmit = (data: BucketDescription) => {
    call('default', data.id, data);
  };

  return (
    <FormProvider {...controls}>
      <form onSubmit={controls.handleSubmit(onSubmit)}>
        <Form schema={schema}>
          <Grid
            container
            spacing={2}>
            <Grid
              item
              md={12}>
              <FormControl
                path="id"
                bucket="default"
                control={UploadInput}
              />
              <FormControl path="name" />
              <FormControl
                path="description"
                control={TextArea}
              />
            </Grid>
          </Grid>
        </Form>
        <Box mt={5}>
          <Button
            fluent
            loading={isLoading}
            disabled={!controls.formState.isValid}
            type="submit"
            color="primary">
            {t('Save')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
