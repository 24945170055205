import { useApi, UseApiSuccessEvent } from '@hooks/useApi';
import { deleteClient } from '@services/api/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type UseRemoveClient = {
  id?: string;
  onSuccess: () => void;
};

export const useRemoveClient = ({ id, onSuccess }: UseRemoveClient) => {
  const { t } = useTranslation();
  const { call, isLoading } = useApi({
    initialLoading: false,
    method: deleteClient,
    onSuccess: useCallback<UseApiSuccessEvent<void>>(
      ({ onSuccessNotification }) => {
        onSuccessNotification(t('Client has been removed'));
        onSuccess();
      },
      [t, onSuccess],
    ),
  });

  return {
    onRemove: useCallback(() => {
      if (id) {
        call(id);
      }
    }, [call, id]),
    isLoading,
  };
};
