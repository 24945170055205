import { styled } from '@mui/material';
import { WithForwardRef } from '@utils/types';
import { forwardRef } from 'react';

import { Button, ButtonProps } from './Button';

type IconButtonProps = Omit<ButtonProps, 'children'> & {
  icon: React.ReactNode;
  dense?: boolean;
};

export const IconButtonComponent: React.FC<WithForwardRef<IconButtonProps, HTMLButtonElement>> = ({
  icon,
  dense = false,
  forwardRef,
  ...props
}) => (
  <StyledButton
    {...props}
    ref={forwardRef}
    dense={dense}>
    {icon}
  </StyledButton>
);

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
  <IconButtonComponent
    {...props}
    forwardRef={ref}
  />
));

const StyledButton = styled(Button)<{ dense: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: auto;

  padding: ${(props) => (props.dense ? '0.25rem .5rem' : '0.6rem 1rem')};
`;
