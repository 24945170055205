import { Icon } from '@common/Icon/Icon';
import { styled } from '@mui/material/styles';

type TodoListItemStatusToggleProps = {
  id: string;
  completed: boolean;
  disabled?: boolean;
  onChange: (completed: boolean) => void;
};

export const TodoListItemStatusToggle = ({ id, completed, disabled, onChange }: TodoListItemStatusToggleProps) => (
  <>
    <ToDoListStyledInput
      id={id}
      type="checkbox"
      name={`${id}-completed`}
      checked={completed}
      disabled={disabled}
      onChange={(e) => {
        if (!disabled) {
          onChange(e.target.checked);
        }
      }}
    />
    <ToDoListStatusIcon
      color={completed ? 'success' : 'contained'}
      name={completed ? 'selected' : 'deselected'}
    />
  </>
);

const ToDoListStatusIcon = styled(Icon)`
  font-size: 1.2rem;
  padding-right: 1rem;
`;

const ToDoListStyledInput = styled(`input`)`
  position: absolute;
  left: -99999999px;
  opacity: 0;
`;
