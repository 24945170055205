import { Icon } from '@common/Icon/Icon';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledFile = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0 0.5em;
`;

type MimeProps = { value: string; className?: string; description?: boolean };

export const Mime: React.FC<MimeProps> = ({ value, className, description = true }) => {
  const { t } = useTranslation();

  if (value.includes('image/')) {
    return (
      <StyledFile className={className}>
        <Icon name="image" />
        {description && t('Image')}
      </StyledFile>
    );
  }

  if (value.includes('text/') || value.includes('pdf') || value.includes('word')) {
    return (
      <StyledFile className={className}>
        <Icon name="document" />
        {description && t('Document')}
      </StyledFile>
    );
  }

  return (
    <StyledFile className={className}>
      <Icon name="file" />
      {description && t('File')}
    </StyledFile>
  );
};
