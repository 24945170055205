import { useLinkClickHandler } from 'react-router-dom';

import { type ButtonProps, Button } from './Button';

type LinkButtonProps = ButtonProps & {
  to: string;
};

export const LinkButton = ({ to, children, ...others }: LinkButtonProps) => {
  const handleClick = useLinkClickHandler<HTMLButtonElement>(to);

  return (
    <Button
      {...others}
      onClick={handleClick}>
      {children}
    </Button>
  );
};
