import { UserPermission } from '@services/model/user';
import { RouteGroup } from '@services/routes/type';

import { UsersEditPage } from './UsersEditPage/UsersEditPage';
import { UsersListPage } from './UsersListPage/UsersListPage';

export const routes: RouteGroup = {
  path: '/users',
  default: '/manage',
  routes: [
    {
      path: 'manage',
      permission: UserPermission.ADMIN,
      component: UsersListPage,
    },
    {
      path: ':userId/edit',
      permission: UserPermission.ADMIN,
      component: UsersEditPage,
    },
  ],
};
