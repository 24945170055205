import { OutlinedInput, styled, Theme } from '@mui/material';
import { WithForwardRef } from '@utils/types';
import { forwardRef } from 'react';

import { FormFieldType } from './types';

type StringInputStyleProps = {
  disabled: boolean;
  readonly: boolean;
  variant: 'normal' | 'contained';
};

type StringInputProps = WithForwardRef<
  FormFieldType<string> & {
    type?: 'password' | 'email' | 'string';
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  } & Partial<StringInputStyleProps>,
  HTMLInputElement
>;

export const StringInputComponent: React.FC<StringInputProps> = ({
  forwardRef,
  placeholder,
  value,
  name,
  error,
  type,
  onChange,
  onBlur,
  disabled = false,
  readonly = false,
  endAdornment,
  startAdornment,
  variant = 'normal',
  onClick,
}) => (
  <OutlinedInputStyled
    onClick={onClick}
    ref={forwardRef}
    name={name}
    value={value ?? ''}
    type={type}
    placeholder={placeholder}
    error={!!error?.message}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled || readonly}
    readonly={readonly}
    startAdornment={startAdornment}
    endAdornment={endAdornment}
    variant={variant}
  />
);

const inputVariant = (theme: Theme, variant: StringInputStyleProps['variant']) => {
  if (variant === 'normal') {
    return '';
  }

  return `
    .MuiInputBase-input {
      padding-top: .5rem;
      padding-bottom: .5rem;
    } 

    fieldset {
      display: none;
    }
  `;
};

export const OutlinedInputStyled = styled(OutlinedInput)<StringInputStyleProps>`
  ${({ disabled, readonly, theme }) =>
    disabled &&
    !readonly &&
    `
    opacity: 1;
    color: ${theme.palette.grey[500]};

  `}

  ${({ variant, theme }) => inputVariant(theme, variant)}
`;

export const StringInput = forwardRef<HTMLInputElement, Omit<StringInputProps, 'forwardRef'>>((props, ref) => (
  <StringInputComponent
    {...props}
    forwardRef={ref}
  />
));
