import { Heading } from '@common/Typography/Heading';
import { Paragraph } from '@common/Typography/Paragraph';
import { Box, styled } from '@mui/material';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type ContentHeaderProps = {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  actions?: ReactNode;
};

const ContentHeaderStyled = styled(Box)`
  padding: 2rem 1rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};

  grid-area: header;
`;

export const ContentHeader = ({ title, subtitle, children, actions }: ContentHeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t(process.env.REACT_APP_NAME)} - ${title}`}</title>
        {subtitle && (
          <meta
            name="description"
            content={subtitle}
          />
        )}
      </Helmet>
      <ContentHeaderStyled>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row">
          <Box>
            <Heading size={4}>{title}</Heading>
            <Paragraph variant="secondary">{subtitle}</Paragraph>
          </Box>
          <ContentHeaderActions>{actions}</ContentHeaderActions>
        </Box>
        {children}
      </ContentHeaderStyled>
    </>
  );
};

const ContentHeaderActions = styled(Box)`
  margin-left: auto;
  margin-right: 0;

  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
