import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';

import { NavItemType } from './types';

type NavItemProps = NavItemType & {
  disabled?: boolean;
};

type NavItemStyledProps = {
  active: boolean;
  icon: boolean;
  disabled: boolean;
};

const NavItemStyled = styled('span')<NavItemStyledProps>`
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 0 0.5rem;

  font-size: 1.3em;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.grey[900]};

  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    `
    background: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    
  `}

  ${({ active, theme }) =>
    !active &&
    `
    &:hover {
      background: ${theme.palette.grey[200]}
    }
  `}
`;

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

export const NavItem = ({ title, path, icon, disabled = false }: NavItemProps) => (
  <NavLinkStyled to={path}>
    {({ isActive }) => (
      <NavItemStyled
        active={isActive}
        icon={typeof icon !== 'undefined'}
        disabled={disabled}>
        <Box>{icon}</Box>
        <Box>{title}</Box>
      </NavItemStyled>
    )}
  </NavLinkStyled>
);

type NavItemClickableProps = Omit<NavItemProps, 'path'> & {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const NavItemClickable = ({ title, icon, onClick, disabled = false }: NavItemClickableProps) => (
  <NavItemStyled
    onClick={onClick}
    active={false}
    icon={typeof icon !== 'undefined'}
    disabled={disabled}>
    <Box>{icon}</Box>
    <Box>{title}</Box>
  </NavItemStyled>
);
