export enum NotificationSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  NOTICE = 'info',
  SUCCESS = 'success',
}

export type NotificationType = {
  id: string;
  message: string;
  severity: NotificationSeverity;
};
