import { styled } from '@mui/material';
import { selectUser } from '@store/user/selectors';
import { capitalize } from '@utils/fp';
import { useSelector } from 'react-redux';

export const CommuneName = () => {
  const user = useSelector(selectUser);

  if (!user) {
    return null;
  }

  return (
    <Container>
      <Commune>{capitalize(user.county || '')}</Commune>
      <Commune>{capitalize(user.commune || '')}</Commune>
    </Container>
  );
};

const Container = styled(`div`)`
  margin-left: auto;
  text-align: right;
`;

const Commune = styled('div')`
  color: ${({ theme }) => theme.palette.grey[600]};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-align: right;

  > span:last-child {
    padding-left: 0.5rem;
  }
`;
