import { Meeting } from '@services/model/client/Meeting';
import { t } from 'i18next';
import { orderBy } from 'lodash';

import { ToDoItem } from './ToDoList/types';

export const mapMeetings = (meetings: Meeting[]): ToDoItem[] =>
  orderBy(
    meetings.map((item, index) => ({
      ...item,
      id: String(item.id || index),
      title: t(item.type),
    })),
    ['creationTime'],
  );
