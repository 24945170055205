import { ColorVariants } from '@common/types';
import { styled, SxProps, Typography, TypographyProps } from '@mui/material';
import { Theme } from '@services/theme';

const sizes = {
  1: '1em',
  2: '1.15em',
  3: '1.25em',
};

const weights = {
  100: 'fontWeightLight',
  200: 'fontWeightNormal',
  300: 'fontWeightMedium',
  500: 'fontWeightRegular',
  900: 'fontWeightBold',
};

type Size = keyof typeof sizes;
type Weight = keyof typeof weights;

type ParagraphType = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  className?: string;
  space?: boolean | number;
  color?: ColorVariants | 'text.secondary';
  size?: Size;
  variant?: 'primary' | 'secondary';
  weight?: Weight;
};

const clickableStyle = (theme: Theme, clickable: boolean) => {
  if (!clickable) {
    return '';
  }

  return `
    color: ${theme.palette.primary.main};
    cursor: pointer;
  `;
};

const TypographyStyled = styled(Typography)<
  Omit<TypographyProps, 'variant'> & { type: string; weight: Weight; clickable: boolean }
>`
  ${({ theme, type }) => type === 'secondary' && `color: ${theme.palette.grey[600]} !important;`}

  font-weight: ${({ theme, weight }) =>
    // @ts-ignore
    theme.typography[weights[weight]]};

  ${({ theme, clickable }) => clickableStyle(theme, clickable)}
`;

export const Paragraph = ({
  sx,
  children,
  space,
  className,
  color,
  size = 1,
  variant = 'primary',
  weight = 200,
  ...rest
}: ParagraphType & React.HTMLAttributes<HTMLParagraphElement>) => (
  <TypographyStyled
    {...rest}
    clickable={Boolean(rest.onClick)}
    color={color}
    weight={weight}
    type={variant}
    sx={{ ...(sx || {}), fontSize: sizes[size] }}
    className={className}
    gutterBottom={space !== 0}
    mb={space ? 3 : undefined}>
    {children}
  </TypographyStyled>
);
