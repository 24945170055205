import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FormField } from './FormField';
import { useFormControl } from './hooks/useFormControl';

type FormControlProps = {
  path: string;
  disabled?: boolean;
  children?: React.ReactNode;
  [key: string]: any;
};

export const FormControl: React.FC<FormControlProps> = ({ path, children, disabled = false, ...rest }) => {
  const { field, fieldState, schema, isRequired, control } = useFormControl({ path });
  const { t } = useTranslation();

  if (!schema) {
    return (
      <Alert color="error">
        {t(`Field described as {{path}} was not found`, {
          path,
        })}
      </Alert>
    );
  }

  return (
    <FormField
      {...field}
      {...fieldState}
      children={children}
      disabled={disabled}
      forwardRef={field.ref}
      control={control}
      label={t(schema?.title)}
      required={isRequired}
      noLabel={schema.type === 'object' || (schema.type === 'boolean' && !schema.enum)}
      {...rest}
    />
  );
};
