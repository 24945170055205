import IdentitySchema from '@assets/schema/client/identity.json';
import { Card } from '@common/Card/Card';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { useApi } from '@hooks/useApi';
import { Grid } from '@mui/material';
import { updateClientIdentity } from '@services/api/client';
import { Client } from '@services/model/client/Client';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormHeader } from './FormHeader';
import { useClientContext } from './useClientTabsContext';

export const IdentityTab = () => {
  const { client, patch } = useClientContext();

  const { schema, controls, onErrorEvent } = useJsonSchema({
    schema: IdentitySchema,
    mode: 'onBlur',
    defaultValues: client.identity,
  });

  const { isLoading, call } = useApi({
    method: updateClientIdentity,
    initialLoading: false,
    onSuccess: useCallback(
      ({ data }: { data: Client }) => {
        patch(() => data);
      },
      [patch],
    ),
    onError: onErrorEvent,
  });

  const onSubmit = useCallback(
    (values: Record<string, any>) => {
      if (!isEqual(client.identity, values)) {
        call(client.id, values);
      }
    },
    [call, client.id, client.identity],
  );

  const { t } = useTranslation();

  return (
    <FormProvider {...controls}>
      <Card>
        <form onBlur={controls.handleSubmit(onSubmit)}>
          <Form schema={schema}>
            <Grid
              container
              spacing={2}>
              <Grid
                item
                xs={12}>
                <FormHeader
                  title={t('House members')}
                  loading={isLoading}
                />
              </Grid>
              <Grid
                item
                xs={12}>
                <FormControl path="houseMembersDetails" />
              </Grid>
              <Grid
                item
                xs={12}>
                <FormHeader
                  title={t('Workforce')}
                  loading={isLoading}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="workingPeopleNumber" />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="retiredPeopleNumber" />
              </Grid>
              <Grid
                item
                xs={12}>
                <FormHeader
                  title={t('Benefits')}
                  loading={isLoading}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="housingBenefit" />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="childBenefit" />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}>
                <FormControl path="energyBenefit" />
              </Grid>
              <Grid
                item
                xs={12}>
                <FormHeader
                  title={t('Additional')}
                  loading={isLoading}
                />
              </Grid>
              <Grid
                item
                xs={12}>
                <FormControl path="description" />
              </Grid>
            </Grid>
          </Form>
        </form>
      </Card>
    </FormProvider>
  );
};
