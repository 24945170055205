import InterviewSchema from '@assets/schema/client/interview.json';
import { Card } from '@common/Card/Card';
import { CardsLayout } from '@common/Card/CardsLayout';
import { Form } from '@common/Form/Form';
import { FormControl } from '@common/Form/FormControl';
import { SchemaObject, useJsonSchema } from '@common/Form/hooks/useJsonSchema';
import { TextArea } from '@common/Form/TextArea';
import { UploadInput } from '@common/Form/UploadInput';
import { useApi } from '@hooks/useApi';
import Grid from '@mui/material/Grid';
import { updateInterview } from '@services/api/client';
import { Client } from '@services/model/client/Client';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormHeader } from './FormHeader';
import { useClientContext } from './useClientTabsContext';

const limit = (client: Client) => client.interview;

export const InterviewTab = () => {
  const { client, patch } = useClientContext();
  const { t } = useTranslation();

  const { controls, schema, onErrorEvent } = useJsonSchema({
    schema: InterviewSchema as unknown as SchemaObject,
    defaultValues: limit(client),
  });

  const { isLoading, call } = useApi({
    method: updateInterview,
    initialLoading: false,
    onSuccess: useCallback(({ data }: { data: Client }) => patch(() => data), [patch]),
    onError: onErrorEvent,
  });

  const onSubmit = (value: Client['interview']) => {
    if (isLoading) {
      return;
    }

    if (!isEqual(client.interview, value)) {
      call(client.id, value);
    }
  };

  const handler = controls.handleSubmit(onSubmit);

  return (
    <FormProvider {...controls}>
      <Form schema={schema}>
        <CardsLayout>
          <Card full>
            <form
              onBlur={handler}
              onSubmit={handler}>
              <Grid
                container
                spacing={2}>
                <Grid
                  item
                  md={12}>
                  <FormHeader
                    title={t('Interview')}
                    loading={isLoading}
                  />
                </Grid>
                <Grid
                  item
                  md={12}>
                  <FormControl
                    path="description"
                    control={TextArea}
                  />
                </Grid>
                <Grid
                  item
                  md={12}>
                  <FormControl
                    path="attachment"
                    bucket="client"
                    fancy
                    control={UploadInput}
                  />
                </Grid>
              </Grid>
            </form>
          </Card>
        </CardsLayout>
      </Form>
    </FormProvider>
  );
};
