import { Modal } from '@common/Modal/Modal';
import { useApi, UseApiErrorEvent } from '@hooks/useApi';
import { replace } from '@lagunovsky/redux-react-router';
import { getClientToDo } from '@services/api/client';
import { dirname } from '@utils/fp';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { useClientContext } from '../../Tabs/useClientTabsContext';
import { ModalBody } from './ModalBody';
import { Params } from './types';

type ToDoListUpdateModalProps = {};

export const ToDoListUpdateModal: React.FC<ToDoListUpdateModalProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id, type } = useParams<Params>();
  const {
    client: { id: clientId },
  } = useClientContext();

  const onClose = useCallback(() => {
    dispatch(replace(dirname(dirname(pathname))));
  }, [pathname, dispatch]);

  const { call, isLoading, data } = useApi({
    method: getClientToDo,
    onError: useCallback<UseApiErrorEvent>(
      ({ onErrorNotification }) => {
        onErrorNotification(t('Unable to load requested recommendation'));
        onClose();
      },
      [onClose, t],
    ),
  });

  useEffect(() => {
    call(type, clientId, id);
  }, [call, id, type, clientId]);

  return (
    <Modal
      cancelable
      open
      loading={isLoading}
      title={t('Action recommendation')}
      onClose={onClose}>
      {typeof data !== 'undefined' && (
        <ModalBody
          item={data}
          onUpdated={onClose}
          onRemoved={onClose}
        />
      )}
    </Modal>
  );
};
