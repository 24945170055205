import styled from '@mui/material/styles/styled';
import { UserPermission } from '@services/model/user';
import { selectUserPermission } from '@store/user/selectors';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

import { Content } from './Content';
import { Sidebar } from './Sidebar';

export const LayoutStyled = styled('div')`
  display: grid;
  grid-template: 'sidebar content' / 20rem auto;
  gap: 0rem;
  height: 100%;
`;

export const Layout = () => {
  const permission = useSelector(selectUserPermission);

  if (permission === UserPermission.NOT_ALLOWED) {
    return <Navigate to="/login" />;
  }

  return (
    <LayoutStyled>
      <Sidebar />
      <Content />
    </LayoutStyled>
  );
};
