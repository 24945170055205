import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import React from 'react';

export type ItemCloseFn = (event: Event | React.SyntheticEvent) => void;

export type ItemType = {
  icon: React.ReactNode;
  text: string;
  onClose?: ItemCloseFn;
};

export const DropdownItem: React.FC<ItemType> = ({ icon, text, onClose }) => (
  <MenuItem onClick={onClose}>
    <ListItemIcon>{icon}</ListItemIcon>
    <Typography variant="body2">{text}</Typography>
  </MenuItem>
);
