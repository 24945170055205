import { createTheme } from '@mui/material/styles';

const colors = {
  white: '#fff',
  black: '#2f2e14',
  primary: {
    main: '#5F8DFA',
  },
  secondary: {
    light: '#dee2e6',
    main: '#ced4da',
    dark: '#adb5bd',
  },
  success: {
    main: '#399939',
  },
  warning: {
    main: '#fcee21',
    dark: '#fca600',
  },
  error: {
    main: '#ff6000',
  },
};

export const theme = createTheme({
  typography: {
    fontSize: 16,
  },
  palette: {
    common: {
      white: colors.white,
      black: colors.black,
    },
    primary: {
      contrastText: colors.white,
      main: colors.primary.main,
    },
    secondary: colors.secondary,
    success: {
      contrastText: colors.white,
      main: colors.success.main,
    },
    warning: {
      contrastText: colors.black,
      main: colors.warning.main,
      dark: colors.warning.dark,
    },
    error: {
      contrastText: colors.white,
      main: colors.error.main,
    },
    grey: {
      900: colors.black,
    },
  },
  shape: {
    // borderRadius: 25,
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '1rem 0.75rem',
          height: 'auto',
        },
      },
    },
  },
});

export type Theme = typeof theme;
