import { StringInput } from '@common/Form/StringInput';
import { Icon } from '@common/Icon/Icon';
import { styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

type SearchInputProps = {
  placeholder?: string;
  onChange: (next: string) => void;
};

export const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onChange }) => {
  const [value, setValue] = useState('');
  const onChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(() => e.target.value);
  }, []);

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  return (
    <SearchInputStyled>
      <Icon name="search" />
      <StringInput
        value={value}
        onChange={onChangeCallback}
        name="search"
        variant="contained"
        placeholder={placeholder}
        endAdornment={
          <Icon
            name="close"
            onClick={() => {
              setValue('');
            }}
            hidden={value.length === 0}
          />
        }
      />
    </SearchInputStyled>
  );
};

const SearchInputStyled = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 50rem;

  padding-left: 1rem;
  padding-right: 0;
`;
