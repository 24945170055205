import { usePermission } from '@hooks/usePermission';
import FormControl from '@mui/material/FormControl';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxInput } from './CheckboxInput';
import { FormFieldStyled } from './FormField';
import { PropertyTypes, SchemaArray } from './hooks/useJsonSchema';
import { Label } from './Label';

type ArrayFieldProps = {
  schema: SchemaArray;
  prefix: string;
};

export const ArrayField: React.FC<ArrayFieldProps> = ({ schema, prefix }) => {
  const { t } = useTranslation();

  const { items } = schema;

  const values = useMemo(() => {
    if (items.type === PropertyTypes.String && items.enum) {
      // create a set of checkbox for this type of schema
      return items.enum;
    }

    return [];
  }, [items]);

  const { setValue } = useFormContext();
  const { canWrite } = usePermission();

  return (
    <FormFieldStyled>
      {schema.title && <Label id={prefix}>{t(schema.title)}</Label>}
      {values.map((v, idx) => (
        <FormControl>
          <Controller
            name={prefix}
            render={({ field, fieldState }) => (
              <CheckboxInput
                {...field}
                disabled={!canWrite()}
                value={v}
                checked={field.value?.includes(v)}
                name={prefix}
                label={String(v)}
                error={fieldState.error}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = e.target;
                  const current = field.value || [];

                  if (current.includes(value)) {
                    setValue(
                      prefix,
                      current.filter((v: string) => v !== value),
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      },
                    );

                    return;
                  }

                  setValue(prefix, [...current, value]);
                }}
              />
            )}
          />
        </FormControl>
      ))}
    </FormFieldStyled>
  );
};
