import { Heading } from '@common/Typography/Heading';
import { styled } from '@mui/material';
import { FCC } from '@utils/types';

import { DrawerContent } from './DrawerContent';

export const DrawerHeader: FCC<{}> = ({ children }) => (
  <DrawerContent>
    <DrawerHeaderStyled
      gutterBottom={false}
      size={6}>
      {children}
    </DrawerHeaderStyled>
  </DrawerContent>
);

const DrawerHeaderStyled = styled(Heading)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
