import { DropdownItem } from '@common/Dropdown/Item';
import { Icon } from '@common/Icon/Icon';
import { useTranslation } from 'react-i18next';

import { useRemoveUser } from './useRemoveUser';

type RemoveUserItemProps = {
  id: string;
  onSuccess: () => void;
};

export const RemoveUserItem: React.FC<RemoveUserItemProps> = ({ id, onSuccess }) => {
  const { t } = useTranslation();
  const { onRemove } = useRemoveUser({
    id,
    onSuccess,
  });

  return (
    <DropdownItem
      onClose={onRemove}
      text={t('Remove user')}
      icon={<Icon name="remove" />}
    />
  );
};
