import { Dropdown } from '@common/Dropdown/Dropdown';
import { DropdownItem, ItemCloseFn } from '@common/Dropdown/Item';
import { Mime } from '@common/File/Mime';
import { Icon } from '@common/Icon/Icon';
import { Spinner } from '@common/Spinner/Spinner';
import { useApi } from '@hooks/useApi';
import { styled } from '@mui/material';
import { DownloadLink } from '@screens/Bucket/BucketListPage/DownloadLink';
import { getFromBucket, uploadToBucket } from '@services/api/buckets';
import React, { ChangeEvent, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StringInput } from './StringInput';
import { FormFieldType } from './types';

export type UploadInputProps = FormFieldType<string> & {
  bucket: string;
  fancy?: boolean;
};

export const UploadInput: React.FC<UploadInputProps> = ({
  bucket,
  fancy = false,
  name,
  value,
  onBlur,
  onChange: _onChange,
  disabled = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setError, setValue } = useFormContext();
  const { t } = useTranslation();
  const { call, isLoading, mock } = useApi({
    method: uploadToBucket,
  });

  const {
    call: fetch,
    isLoading: isFetching,
    data,
    clear,
  } = useApi({
    method: getFromBucket,
  });

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files || files?.length === 0) {
      setError(name, {
        type: 'custom',
        message: t('File not specified'),
      });

      return;
    }

    try {
      const data = await call(bucket, files[0]);

      if (!data) {
        return;
      }

      setValue(name, data?.id, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });

      mock(data);
    } finally {
      const form = inputRef.current?.closest('form');

      if (form) {
        form.dispatchEvent(
          new Event('submit', {
            cancelable: true,
            bubbles: true,
          }),
        );
      }

      if (onBlur) {
        onBlur();
      }
    }
  };

  const onClick = () => {
    if (inputRef.current && (!isLoading || !isFetching)) {
      inputRef.current.click();
    }
  };

  const onRemove = (onClose: ItemCloseFn) => (e: Event | React.SyntheticEvent) => {
    e.preventDefault();

    onClose(e);

    setValue(name, '', {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });

    const form = inputRef.current?.closest('form');

    if (form) {
      form.dispatchEvent(
        new Event('submit', {
          cancelable: true,
          bubbles: true,
        }),
      );
    }

    clear();
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    fetch(bucket, value);
  }, [fancy, value, fetch, bucket]);

  return (
    <>
      <input
        hidden
        ref={inputRef}
        type="file"
        onChange={onChange}
        disabled={disabled}
      />
      {!fancy && (
        <StringInput
          name={name}
          value={data?.name}
          type="string"
          readonly
          onClick={onClick}
          disabled={disabled}
          startAdornment={
            <>
              {(isLoading || isFetching) && <Spinner size={18} />}
              {!(isLoading || isFetching) && <Icon name="upload" />}
            </>
          }
        />
      )}
      {fancy && (
        <FileBox
          onClick={!isFetching && !data?.id && !disabled ? onClick : undefined}
          disabled={disabled}>
          {isFetching && (
            <>
              <Spinner size={24} />
            </>
          )}
          {!isFetching && !data?.id && (
            <>
              <FileIcon>
                <>
                  {isLoading && <Spinner size={24} />}
                  {!isLoading && <Icon name="upload" />}
                </>
              </FileIcon>
              <FileInfo>
                {isLoading && t('Uploading')}
                {!isLoading && t('Click to upload')}
              </FileInfo>
            </>
          )}
          {data?.id && (
            <>
              <FileIcon>
                <StyledMime
                  value={data.mime}
                  description={false}
                />
              </FileIcon>
              <FileInfo>{data.name}</FileInfo>
              <Dropdown
                disabledPortal
                id={data.id}
                items={({ onClose }) => (
                  <span>
                    <DropdownItem
                      onClose={onRemove(onClose)}
                      icon={<Icon name="remove" />}
                      text={t('Remove')}
                    />
                    <DownloadLink id={data.id} />
                  </span>
                )}
              />
            </>
          )}
        </FileBox>
      )}
    </>
  );
};

const FileBox = styled('div')<{ disabled: boolean }>`
  padding: 1rem 2rem;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0 1rem;
  max-width: 500px;
  min-height: 70px;

  ${({ disabled, theme }) =>
    disabled &&
    `

    opacity: .5;
    background: ${theme.palette.grey[200]};
  `}
`;

const FileIcon = styled('div')`
  font-size: 1.5rem;
`;

const FileInfo = styled(`div`)`
  margin: auto;
  overflow: hidden;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const StyledMime = styled(Mime)``;
